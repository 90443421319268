<script>
    import('@css/components/spinners.scss');

    const {
        color
    } = $props();

    const childClass = color === 'primary' ? 'spinner-child spinner-child-primary' : 'spinner-child';
</script>

<div class="spinner-animation spinner-three-bounce">
    <div class={`${childClass} spinner-bounce1`}></div>
    <div class={`${childClass} spinner-bounce2`}></div>
    <div class={`${childClass} spinner-bounce3`}></div>
</div>
