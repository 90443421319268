<script>
    import I18n from '@js/modules/translations';

    import Dropdown from '@js/components/themes/Dropdown.svelte';

    import Icon from '@js/components/themes/Icon.svelte';
    import runIcon from '@js/components/icons/runIcon';
    import hikeIcon from '@js/components/icons/hikeIcon';
    import bicycleIcon from '@js/components/icons/bicycleIcon';
    import walkIcon from '@js/components/icons/walkIcon';

    const ROUTE_TYPE_ICON = {
        running: runIcon,
        hiking: hikeIcon,
        bike: bicycleIcon,
        walk: walkIcon
    };

    const {
        name,
        label = I18n.t('js.ride.model.ride_type'),
        defaultValue = window.currentUserRideType || 'running',
        onRideTypeChange
    } = $props();

    let rideType = $state(defaultValue);

    const _handleRideTypeChange = (routeType, event) => {
        event.preventDefault();

        rideType = routeType;

        if (onRideTypeChange) {
            onRideTypeChange(routeType);
        }
    };
</script>

<Dropdown label={label}
          class="mx-auto w-full"
          buttonClass="w-full px-4 py-2 flex justify-between rounded-lg text-sm"
          color="gray"
          name={name}
          optionSize={Object.keys(ROUTE_TYPE_ICON).length}
          selected={rideType}
          closeClickInside={true}>
    {#snippet buttonRender()}
    <span class="flex items-center justify-center">
        <Icon class="inline-flex h-4 w-4 me-2"
              icon={ROUTE_TYPE_ICON[rideType]}/>
        {I18n.t(`js.ride.enums.ride_type.${rideType}`)}
    </span>
    {/snippet}

    <ul class="text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="RouteType">
        {#each Object.keys(ROUTE_TYPE_ICON) as routeType}
            <li class="flex items-center justify-start p-2 cursor-pointer hover:bg-gray-300"
                class:bg-primary-200={rideType === routeType}
                onclick={_handleRideTypeChange.bind(null, routeType)}>
                <Icon class="h-4 w-4 me-3"
                      icon={ROUTE_TYPE_ICON[routeType]}/>
                {I18n.t(`js.ride.enums.ride_type.${routeType}`)}
            </li>
        {/each}
    </ul>
</Dropdown>
