<script>
    import {slide} from 'svelte/transition';

    import classnames from 'classnames';

    import Button from '@js/components/themes/Button.svelte';

    const {
        color = 'primary',
        closeClickInside = false,
        optionSize,
        class: className,
        buttonClass,
        label,
        buttonName,
        name,
        selected,
        disabled,
        children,
        onClose,
        // slots
        buttonRender
    } = $props();

    const ELEMENT_HEIGHT = 40;

    let buttonElement = $state();
    let isOpen = $state(false);
    let dropdownDirection = $state('down');

    const close = () => {
        isOpen = false;

        if (onClose) {
            onClose();
        }
    };

    const computeDropdownDirection = () => {
        if (!buttonElement || !optionSize) {
            return;
        }

        const windowHeight = window.innerHeight;
        const menuHeight = optionSize * ELEMENT_HEIGHT;
        const instOffsetWithMenu = buttonElement.getBoundingClientRect().bottom + menuHeight;

        dropdownDirection = instOffsetWithMenu >= windowHeight ? 'up' : 'down';
    };

    const onButtonClick = () => {
        computeDropdownDirection();

        isOpen = !isOpen;
    };

    const clickOutside = (element, callbackFunction) => {
        function onClick(event) {
            if (!element || !buttonElement) {
                return;
            }

            if ((closeClickInside || !element.contains(event.target)) && !buttonElement.contains(event.target)) {
                callbackFunction();
            }
        }

        document.body.addEventListener('click', onClick);

        return {
            update(newCallbackFunction) {
                callbackFunction = newCallbackFunction;
            },
            destroy() {
                document.body.removeEventListener('click', onClick);
            }
        };
    };
</script>

<div class={classnames('relative', className)}>
    {#if label}
        <div class="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            {label}
        </div>
    {/if}

    {#if name}
        <input type="hidden"
               name={name}
               value={selected}/>
    {/if}

    <Button type="button"
            class={classnames('inline-flex items-center', buttonClass)}
            color={color}
            size="xs"
            disabled={disabled}
            bind:buttonElement={buttonElement}
            onClick={onButtonClick}>
        {#if buttonRender}
            {@render buttonRender()}
        {:else}
            {buttonName}
        {/if}

        <svg class="w-2.5 h-2.5 ms-3"
             aria-hidden="true"
             xmlns="http://www.w3.org/2000/svg"
             fill="none"
             viewBox="0 0 10 6">
            <path stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 4 4 4-4"/>
        </svg>
    </Button>

    {#if isOpen}
        <div class={classnames('absolute w-full min-w-48 bg-white divide-y divide-gray-100 rounded-lg shadow z-dropdown dark:bg-gray-700 dark:divide-gray-600', dropdownDirection === 'up' && 'top-auto bottom-full mb-1', dropdownDirection === 'down' && 'mt-1')}
             transition:slide={{ duration: 450 }}
             use:clickOutside={close}>
            {@render children()}
        </div>
    {/if}
</div>

