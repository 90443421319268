<script>
    import Icon from '@js/components/themes/Icon.svelte';
    import infoIcon from '@js/components/icons/infoIcon';
</script>

<div id="map-helper-container"
     class="map-helper">
    <Icon class="inline-flex h-4 w-4 me-1 mb-0.5"
          icon={infoIcon}/>

    <span id="map-helper-message">
    </span>
</div>

<!--<div id="map-helper-poi-mode"-->
<!--     class="map-helper">-->
<!--    <Icon class="inline-flex h-4 w-4 me-1 mb-0.5"-->
<!--          icon={infoIcon}/>-->

<!--    {-->
<!--        window.screen.width < 1024-->
<!--            ?-->
<!--            I18n.t('js.maps.helpers.mobile.poi_mode')-->
<!--            :-->
<!--            I18n.t('js.maps.helpers.desktop.poi_mode')-->
<!--    }-->
<!--</div>-->
