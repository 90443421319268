export const videoLazyLoader = () => {
    document.addEventListener('DOMContentLoaded', () => {
        let lazyVideos = [...document.querySelectorAll('video.lazy-load')];

        if ('IntersectionObserver' in window) {
            let lazyVideoObserver = new IntersectionObserver(function (entries) {
                entries.forEach(function (video) {
                    if (video.isIntersecting) {
                        for (let source in video.target.children) {
                            let videoSource = video.target.children[source];
                            if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                                videoSource.src = videoSource.dataset.src;
                            }
                        }

                        if (video.target.dataset.poster) {
                            video.target.poster = video.target.dataset.poster;
                        }

                        try {
                            video.target.load();
                        } catch (_) {
                            // Do nothing
                        }
                        video.target.classList.remove('lazy-load');
                        lazyVideoObserver.unobserve(video.target);
                    }
                });
            });

            lazyVideos.forEach(function (lazyVideo) {
                lazyVideoObserver.observe(lazyVideo);
            });
        } else {
            lazyVideos.forEach(function (video) {
                for (let source in video.children) {
                    let videoSource = video.children[source];
                    if (typeof videoSource.tagName === 'string' && videoSource.tagName === 'SOURCE') {
                        videoSource.src = videoSource.dataset.src;
                    }
                }

                video.load();
                video.classList.remove('lazy-load');
            });
        }
    });
};
