import {
    externalFetch
} from '@js/actions/fetch';

import {pushError} from '@js/actions/errorManagement';

import AnalyticsService from '@js/modules/analyticsService';

const normalizeString = (string) => string ?
    string.normalize('NFD')
        .trim()
        .replace(/[\u0300-\u036f]/g, '')
        .replace(/[\s-_+@#$%^&*,;]/g, '')
        .toLowerCase()
    : string;

const MapRequest = (function () {
    var mapRequest = {
        // Mapbox direction url
        mapPlannerDirectionUrl: 'https://routing.map-planner.com/route',

        // Mapbox direction url
        mapboxDirectionUrl: 'https://api.mapbox.com/directions/v5/mapbox/walking/',

        // Mapbox url
        mapboxStaticMapUrl: 'https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static',

        // Geocoder URL
        geocoderURL: 'https://geocoder.map-planner.com/api/'
    };

    var _normalizePlaceType = function (placeType) {
        switch (placeType) {
            case 'country':
                return 'place';
            case 'county':
                return 'place';
            case 'state':
                return 'place';
            case 'province':
                return 'place';
            case 'city':
                return 'place';
            case 'village':
                return 'place';
            case 'town':
                return 'place';
            case 'hamlet':
                return 'place';
            case 'locality':
                return 'place';
            case 'yes':
                return 'place';

            case 'peak':
                return 'peak';
            case 'volcano':
                return 'peak';

            case 'administrative':
                return 'address';
            case 'neighbourhood':
                return 'address';
            case 'house':
                return 'address';
            case 'apartments':
                return 'address';
            case 'residential':
                return 'address';
            case 'secondary':
                return 'address';
            case 'tertiary':
                return 'address';
            case 'university':
                return 'address';

            case 'station':
                return 'poi';
            case 'bus_stop':
                return 'poi';
            case 'subway_entrance':
                return 'poi';
            case 'orchard':
                return 'poi';
            case 'commercial':
                return 'poi';
            case 'government':
                return 'poi';
            case 'stream':
                return 'poi';
            case 'saddle':
                return 'poi';
            case 'parking':
                return 'poi';
            case 'service':
                return 'poi';
            case 'tree':
                return 'poi';
            case 'shelter':
                return 'poi';
            case 'platform':
                return 'poi';
            case 'viewpoint':
                return 'poi';
            case 'car_rental':
                return 'poi';
            case 'post_office':
                return 'poi';

            // case 'restaurant':
            //     return 'restaurant';

            case 'political':
                return false;

            default:
                return 'other';
        }
    };

    var _buildPlaceName = function (feature) {
        var placeName = [feature.housenumber, feature.name || feature.street].compact()
            .join(' ');

        var placeDetails = [
            feature.city,
            // feature.district || feature.city,
            feature.county || feature.postcode?.split(';')
                ?.first(),
            feature.countrycode === 'FR' ? null : feature.state,
            feature.country
        ].compact();

        if (placeDetails.length) {
            placeName += ` (${placeDetails.join(', ')})`;
        } else {
            placeName += ` (${feature.country})`;
        }

        return placeName;
    };

    var _parsePhotonResponse = function (features) {
        if (features) {
            // Exclude useless data from photon response
            let filteredFeatures = features
                // .filter((feature) => ['place', 'address', 'peak', 'other'].includes(_normalizePlaceType(feature.properties.osm_value)))
                .filter((feature) => !['natural', 'census'].includes(feature.properties.osm_value));

            if (filteredFeatures.length < 3) {
                filteredFeatures = features;
            }

            return filteredFeatures
                .map((feature, index) => ({
                    type: feature.properties.type,
                    osmType: feature.properties.osm_value,
                    placeType: _normalizePlaceType(feature.properties.osm_value),
                    label: feature.properties.name || feature.properties.street,
                    fullName: _buildPlaceName(feature.properties),
                    center: feature.geometry?.coordinates,
                    bbox: feature.properties.extent,
                    country: feature.properties.countrycode,
                    relevance: index
                }));
        } else {
            return [];
        }
    };

    var _buildPhotonGeocodingUrl = function (query, country, language = null, proximity = null, limit = 8) {
        // available layers:
        // house, street, locality, district, city, county, state, country, other (e.g. natural features)

        var geocodingUrl = MapRequest.geocoderURL;

        geocodingUrl += `?q=${encodeURIComponent(query)}`;

        geocodingUrl += `&limit=${limit}`;

        geocodingUrl += '&layer=' + ['house', 'street', 'locality', 'district', 'city', 'state'].join('&layer=');

        // "tourism" and "amenity" tags are useful to find parkings
        geocodingUrl += '&osm_tag=!' + ['building', 'emergency', 'healthcare', 'railway', 'office', 'boundary', 'club', 'craft', 'shop', 'highway', 'landuse', 'unclassified', 'man_made', 'leisure', 'bridge'].join('&osm_tag=!');

        if (proximity && proximity.lat !== 0 && proximity.lng !== 0) {
            var latitude = parseFloat(proximity.lat);
            var longitude = parseFloat(proximity.lng);

            // Ensure lat or long don't end with 0
            latitude = (latitude + 0.001).toFixed(3);
            longitude = (longitude + 0.001).toFixed(3);

            geocodingUrl += `&lat=${encodeURIComponent(latitude)}`;
            geocodingUrl += `&lon=${encodeURIComponent(longitude)}`;

            geocodingUrl += '&zoom=7';
        }

        if (language) {
            if (!['en', 'de', 'fr'].includes(language)) {
                language = 'default';
            }
            geocodingUrl += `&lang=${language}`;
        }

        return geocodingUrl;
    };

    mapRequest.getDirectionsAPIUrl = function (pointsArray, routingService, routeType = 'hike', requestOptions = {}) {
        let url;

        var i;
        var encodedPoints = '';

        if (routingService === 'map_planner') {
            for (i = 0; i < pointsArray.length; i++) {
                if (encodedPoints.length > 0) {
                    encodedPoints += '&';
                }
                encodedPoints += 'point=' + encodeURIComponent(pointsArray[i].lat.toFixed(5)) + ',' + encodeURIComponent(pointsArray[i].lng.toFixed(5));
            }

            url = MapRequest.mapPlannerDirectionUrl + '?' + encodedPoints + '&profile=' + routeType + '&type=json&elevation=false&instructions=false&points_encoded=true';
        } else {
            for (i = 0; i < pointsArray.length; i++) {
                if (encodedPoints.length > 0) {
                    encodedPoints += ';';
                }
                encodedPoints += pointsArray[i].lng.toFixed(5) + ',' + pointsArray[i].lat.toFixed(5);
            }

            url = MapRequest.mapboxDirectionUrl + encodedPoints + '?access_token=' + window.settings.mapbox_key;
        }

        return externalFetch(url, {}, requestOptions);
    };

    mapRequest.locationSearch = function (query, options = {}, requestOptions = {}) {
        var url = _buildPhotonGeocodingUrl(query, options.country, options.locale, options.proximity);

        return externalFetch(url, {}, requestOptions)
            .then(function (data) {
                if (!data || data.errors) {
                    AnalyticsService.trackLocationSearchError(data?.errors);
                }

                if (!data || data.errors || data.abort) {
                    return [];
                }

                var features = _parsePhotonResponse(data.features);

                // Ensure all results are unique according to fullName to avoid duplication in search result
                features = features.uniqBy('fullName');

                if (options.country) {
                    features = data.features.filter((feature) => feature.country.toLowerCase() === options.country.toLowerCase());
                }

                features = features.filter((feature) => !!feature.label);

                return features
                    .compact()
                    .slice()
                    .sort((locationA, locationB) => {
                        if (options.sortByMatchingName) {
                            if (normalizeString(locationA.label)
                                .includes(normalizeString(query))) {
                                return -1;
                            }
                        }

                        if (options.sortByLocaleCountry && options.locale) {
                            if (locationA.country?.toLowerCase() === options.locale.toLowerCase()) {
                                return -1;
                            }
                        }

                        if (options.sortByPlaceType) {
                            if (locationA.type === 'county' && locationB.type === 'state') {
                                return -1;
                            }
                            if (locationA.type === 'city' && locationB.type === 'county') {
                                return -1;
                            }
                            if (locationA.type === 'city' && locationA.osmType === 'village' && locationB.type === 'city' && locationB.osmType === 'town') {
                                return -1;
                            }
                            if (locationA.type === 'district' && locationB.type === 'city') {
                                return -1;
                            }
                            if (locationA.type === 'locality' && locationB.type === 'district') {
                                return -1;
                            }
                        }

                        // Sort by default by relevance
                        if (locationA.relevance < locationB.relevance) {
                            return -1;
                        } else if (locationA.relevance > locationB.relevance) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
            })
            .catch((error) => {
                pushError(error);
            });
    };

    return mapRequest;
})();

export default MapRequest;
