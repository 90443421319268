import StorageService from '@js/modules/storage';


const AnalyticsService = (function () {
    const AnalyticsServiceModel = {};

    const SECURE_DISPATCH_TIMEOUT = 200; // in ms

    const _isMetricsAvailable = function () {
        return window.isMetricsEnabled;
    };

    const _setWithExpiry = function (key, value, ttl) {
        const now = new Date();

        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        };

        StorageService.setItem(key, item);
    };

    const _getWithExpiry = function (key) {
        const item = StorageService.getItem(key);
        if (!item) {
            return null;
        }

        const now = new Date();
        // compare the expiry time of the item with the current time
        if (now.getTime() > item.expiry) {
            // If the item is expired, delete the item from storage and return null
            StorageService.removeItem(key);

            return null;
        }
        return item.value;
    };

    const _isUniqPerSession = function (eventName) {
        const eventInSession = _getWithExpiry(`analytics-${eventName}`);

        if (eventInSession) {
            return false;
        } else {
            // Sessions in Matomo last for 30 minutes
            _setWithExpiry(`analytics-${eventName}`, 'true', 1800000);

            return true;
        }
    };

    const _secureDispatch = function (eventName) {
        return new Promise((resolve) => setTimeout(() => resolve(eventName), SECURE_DISPATCH_TIMEOUT));
    };

    const _trackEvent = function (eventAction, eventName, eventValue, eventValueData) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['trackEvent', eventAction, eventName, eventValue, eventValueData].filter(Boolean));
    };

    const _trackSearch = function (query, resultCount, searchCategory = false) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['trackSiteSearch', query, searchCategory, resultCount]);
    };

    /* Initialization
    ******************** */
    AnalyticsServiceModel.initialize = function () {
        if (!_isMetricsAvailable()) {
            return false;
        }

        // Change how long a tab needs to be active to be counted as viewed in seconds
        // Requires a page to be actively viewed for 10 seconds for any heart beat request to be sent.
        window._paq.push(['enableHeartBeatTimer', 10]);

        return true;
    };

    /* Inscription and connection
    ******************** */
    AnalyticsServiceModel.trackSignupPage = function () {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', '/signup']);
        window._paq.push(['trackPageView', 'Signup']);
    };

    AnalyticsServiceModel.trackSignupSuccess = function (userId) {
        _trackEvent('Subscription', 'signup', userId);

        return _secureDispatch('SignupSuccess');
    };

    AnalyticsServiceModel.trackLoginPage = function () {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', '/login']);
        window._paq.push(['trackPageView', 'Login']);
    };

    AnalyticsServiceModel.trackLoginSuccess = function (userId) {
        _trackEvent('Subscription', 'login', userId);
    };

    /* Map tiles
    ******************** */
    AnalyticsServiceModel.trackMapStyleUsed = function (mapTileService) {
        if (!StorageService.getItem('map_usage_tile_service_tracked')) {
            _trackEvent('Map tiles', 'Tiles service', mapTileService);
            StorageService.setItem('map_usage_tile_service_tracked', 'true');
        }
    };

    AnalyticsServiceModel.trackMapStyleChanged = function (mapTileService) {
        _trackEvent('Map tiles', 'Map style changed', mapTileService);
    };

    AnalyticsServiceModel.trackMapTilesCount = function (mapTileService, differenceTileIdsSize) {
        _trackEvent('Map tiles', 'Tiles loaded', mapTileService, differenceTileIdsSize);
    };

    /* Map limitations
    ******************** */
    AnalyticsServiceModel.trackMapTilesMaxReached = function (mapTileService) {
        _trackEvent('Map limitations', 'Max Tiles reached', mapTileService);
    };

    AnalyticsServiceModel.trackMapDistanceMaxReached = function () {
        _trackEvent('Map limitations', 'Max distance reached');
    };

    /* Map stats
    ******************** */
    AnalyticsServiceModel.trackMapDistanceComputed = function (deltaDistance) {
        _trackEvent('Map stats', 'Compute distance', 'Distance computed', deltaDistance);
    };

    AnalyticsServiceModel.trackMapComputeDuration = function (rideType) {
        _trackEvent('Map stats', 'Compute duration', rideType);
    };

    AnalyticsServiceModel.trackComputeElevationClick = function (pageType) {
        _trackEvent('Map stats', 'Compute elevation', pageType);
    };

    AnalyticsServiceModel.trackGeolocationClick = function (pageType, rideName, rideId) {
        if (pageType === 'Ride show') {
            if (_isUniqPerSession(`geolocation-ride-${rideId}`)) {
                _trackEvent('Geolocation', 'Locate', `${rideName} (${rideId})`);
            }
        } else if (pageType === 'Search') {
            _trackEvent('Geolocation', 'Locate', 'Search page');
        } else if (pageType === 'Home page') {
            _trackEvent('Geolocation', 'Locate', 'Home page');
        }
    };

    AnalyticsServiceModel.trackGeotrackingClick = function (pageType, rideName, rideId) {
        if (pageType === 'Ride show') {
            if (_isUniqPerSession(`geotracking-ride-${rideId}`)) {
                _trackEvent('Geolocation', 'Tracking', `${rideName} (${rideId})`);
            }
        } else if (pageType === 'Explore page') {
            if (_isUniqPerSession('geotracking-explore')) {
                _trackEvent('Geolocation', 'Tracking', 'Explore page');
            }
        }
    };

    /* Home
    ******************** */
    AnalyticsServiceModel.trackHomeShowMapClick = function () {
        if (_isUniqPerSession('home-map-displayed')) {
            _trackEvent('Home', 'Home - Show map');
        }
    };

    AnalyticsServiceModel.trackHomeShowPlaceSelected = function () {
        if (_isUniqPerSession('home-place-selected')) {
            _trackEvent('Home', 'Home - Place selected');
        }
    };

    AnalyticsServiceModel.trackHomePathStarted = function () {
        if (_isUniqPerSession('home-path-started')) {
            _trackEvent('Home', 'Home - Path started');
        }
    };

    AnalyticsServiceModel.trackHomeShowFormClick = function () {
        if (_isUniqPerSession('home-form-displayed')) {
            _trackEvent('Home', 'Home - Show form');
        }
    };

    AnalyticsServiceModel.trackHomeRideSaved = function () {
        if (_isUniqPerSession('home-ride-saved')) {
            _trackEvent('Home', 'Home - Ride saved');
        }
    };

    /* Ride
    ******************** */
    AnalyticsServiceModel.trackSaveRide = function (pageType) {
        _trackEvent('Ride', 'Save ride', pageType);
    };

    AnalyticsServiceModel.trackRideSaved = function (pageType) {
        if (pageType === 'Home page') {
            _trackEvent('Ride', 'Ride saved', pageType);
        } else if (pageType === 'Plan page') {
            _trackEvent('Ride', 'Ride saved', pageType);
        }
    };

    AnalyticsServiceModel.trackShowElevationClick = function (rideName, rideId) {
        _trackEvent('Ride', 'Show elevation', `${rideName} (${rideId})`);
    };

    AnalyticsServiceModel.trackShowWeatherClick = function (rideName, rideId) {
        if (_isUniqPerSession(`weather-${rideId}`)) {
            _trackEvent('Ride', 'Show weather', `${rideName} (${rideId})`);
        }
    };

    AnalyticsServiceModel.trackRouteImported = function (name, duration, coordinatesSize) {
        if (_isUniqPerSession(`track-imported-${name}`)) {
            _trackEvent('Ride', 'Track imported', `${name} - ${duration} (${coordinatesSize} points)`);
        }
    };

    AnalyticsServiceModel.trackRouteExported = function (rideId, rideName) {
        if (_isUniqPerSession(`track-exported-${rideId}`)) {
            _trackEvent('Ride', 'Track exported', rideId && rideName ? `${rideName} (${rideId})` : 'Direct export');
        }
    };

    // AnalyticsServiceModel.trackCreateRideStep = function (step) {
    //     _trackEvent('Plan ride', 'Steps', step);
    // };

    /* Search
    ******************** */
    AnalyticsServiceModel.trackSearch = function (pageType, query, location, resultCount) {
        _trackSearch([query, location].filter(Boolean)
            .join(' / '), resultCount, pageType);
    };

    /* E-commerce
    ******************** */
    AnalyticsServiceModel.trackProductModalShow = function (requiredFunctionality) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', '/product-subscription-modal']);
        window._paq.push(['trackPageView', 'Product subscription modal']);

        _trackEvent('Checkout', 'Product modal', requiredFunctionality);
    };

    AnalyticsServiceModel.trackProductView = function (productId, productName, productPrice) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setEcommerceView', productId, productName, undefined, productPrice]);
        window._paq.push(['trackPageView']);
    };

    AnalyticsServiceModel.trackAddToBasket = function (productId, productName, productPrice) {
        if (!_isMetricsAvailable()) {
            return Promise.resolve();
        }

        window._paq.push(['addEcommerceItem', productId, productName, undefined, productPrice, 1]);
        window._paq.push(['trackEcommerceCartUpdate', productPrice]);

        if (_isUniqPerSession('add-to-basket')) {
            _trackEvent('Checkout', 'Add to basket');
        }

        return _secureDispatch('AddToBasket');
    };

    AnalyticsServiceModel.trackCheckoutPage = function (productId, productName, productPrice) {
        if (!_isMetricsAvailable()) {
            return Promise.resolve();
        }

        window._paq.push(['addEcommerceItem', productId, productName, undefined, productPrice, 1]);

        if (_isUniqPerSession('order-attempt')) {
            _trackEvent('Checkout', 'Order attempt');
        }

        return _secureDispatch('OrderAttempt');
    };

    AnalyticsServiceModel.trackOrderSuccess = function (orderId, orderTotal, orderWithDiscount = false) {
        if (!_isMetricsAvailable()) {
            return Promise.resolve();
        }

        window._paq.push(['trackEcommerceOrder', orderId, orderTotal, undefined, undefined, undefined, orderWithDiscount]);

        if (_isUniqPerSession('order-success')) {
            _trackEvent('Checkout', 'Order success');
        }

        return _secureDispatch('OrderSuccess');
    };

    /* Other pages
    ******************** */
    AnalyticsServiceModel.trackRidePlannedPage = function (rideId) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', `/rides/${rideId}/planned`]);
        window._paq.push(['trackPageView', `Ride planned (${rideId})`]);
    };

    AnalyticsServiceModel.trackRidePerformancePage = function (rideId) {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', `/rides/${rideId}/performance`]);
        window._paq.push(['trackPageView', `Ride performance (${rideId})`]);
    };

    AnalyticsServiceModel.trackRidePoiAssociationPage = function () {
        if (!_isMetricsAvailable()) {
            return;
        }

        window._paq.push(['setCustomUrl', '/pois/ride-association']);
        window._paq.push(['trackPageView', 'Associate ride to POI']);
    };

    /* PWA
    ******************** */
    AnalyticsServiceModel.trackPWAMode = function (mode) {
        if (_isUniqPerSession('pwa-mode')) {
            _trackEvent('PWA', 'PWA mode', mode);
        }
    };

    AnalyticsServiceModel.trackPWAInstalled = function () {
        _trackEvent('PWA', 'PWA installed');
    };

    AnalyticsServiceModel.trackPWAPrompt = function () {
        _trackEvent('PWA', 'PWA install requested');
    };

    /* API and browser errors
    ******************** */
    // AnalyticsServiceModel.trackStorageError = function () {
    //     if (_isUniqPerSession('storage-error')) {
    //         _trackEvent('Error', 'Local storage unavailable');
    //     }
    // };

    AnalyticsServiceModel.trackWebGLError = function () {
        if (_isUniqPerSession('webgl-error')) {
            _trackEvent('Error', 'WebGL unavailable');
        }
    };

    AnalyticsServiceModel.trackLocationSearchError = function (errors) {
        _trackEvent('Error', 'Location search', errors);
    };

    AnalyticsServiceModel.trackRoutingError = function (errors) {
        _trackEvent('Error', 'Routing', errors);
    };

    AnalyticsServiceModel.trackElevationError = function (errors) {
        _trackEvent('Error', 'Elevation', errors);
    };

    return AnalyticsServiceModel;
})();

export default AnalyticsService;
