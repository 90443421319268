import api from '@js/actions/api';

export const computeRideElevation = (elevationData, requestOptions = {}) => (
    api.post('/api/v1/rides/compute-elevation', {
        elevation: elevationData
    }, false, requestOptions)
);

export const computeRideDuration = (durationData, requestOptions = {}) => (
    api.post('/api/v1/rides/estimate-duration', {
        elevation: durationData
    }, false, requestOptions)
);
