<script>
    import I18n from '@js/modules/translations';

    import Dropdown from '@js/components/themes/Dropdown.svelte';

    import Icon from '@js/components/themes/Icon.svelte';
    import visibilityIcon from '@js/components/icons/visibilityIcon';
    import visibilityOffIcon from '@js/components/icons/visibilityOffIcon';

    import {RideVisibilities} from '@js/components/modules/constants';

    const VISIBILITY_ICON = {
        everyone: visibilityIcon,
        only_me: visibilityOffIcon
    };

    const {
        name,
        label = I18n.t('js.ride.model.visibility'),
        defaultValue = 'everyone',
        onVisibilityChange
    } = $props();

    let rideVisibility = $state(defaultValue);

    const _handleVisibilityChange = (visibility, event) => {
        event.preventDefault();

        rideVisibility = visibility;

        if (onVisibilityChange) {
            onVisibilityChange(visibility);
        }
    };
</script>

<Dropdown label={label}
          class="mx-auto w-full"
          buttonClass="w-full px-4 py-2 flex justify-between rounded-lg text-sm"
          color="gray"
          name={name}
          selected={rideVisibility}
          optionSize={RideVisibilities.length}
          closeClickInside={true}>
    {#snippet buttonRender()}
    <span class="flex items-center justify-center">
        <Icon class="inline-flex h-4 w-4 me-2"
              icon={VISIBILITY_ICON[rideVisibility]}/>
        {I18n.t(`js.ride.enums.visibility.${rideVisibility}`)}
    </span>
    {/snippet}

    <ul class="text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="RouteVisibility">
        {#each RideVisibilities as visibility}
            <li class="flex items-center justify-start p-2 cursor-pointer hover:bg-gray-300"
                class:bg-primary-200={rideVisibility === visibility}
                onclick={_handleVisibilityChange.bind(null, visibility)}>
                <Icon class="h-4 w-4 me-3"
                      icon={VISIBILITY_ICON[visibility]}/>
                {I18n.t(`js.ride.enums.visibility.${visibility}`)}
            </li>
        {/each}
    </ul>
</Dropdown>
