<script>
    import I18n from '@js/modules/translations';
    import * as Utils from '@js/modules/utils';

    import {fetchRides} from '@js/actions/rideActions';

    import RideCard from '@js/components/ride/RideCard.svelte';

    import StorageService from '@js/modules/storage';

    const {
        class: className,
        titleClass,
        separator = false
    } = $props();

    let visitedRides = $state([]);

    const localVisitedRides = StorageService.getLocalData('rides-visited');
    if (Utils.isPresent(localVisitedRides)) {
        fetchRides({
            rideIds: localVisitedRides
                .reverse()
                .limit(4)
        })
            .then((response) => {
                visitedRides = response?.rides;
            });
    }
</script>

{#if visitedRides && visitedRides.length > 0}
    <div class={className}>
        <h2 class={titleClass || 'text-3xl mb-1'}>
            {I18n.t('js.views.popular.title_visited')}
        </h2>

        <div class="row p-4">
            {#each visitedRides as visitedRide (visitedRide.id)}
                <div class="col s12 m6 l4">
                    <RideCard ride={visitedRide}/>
                </div>
            {/each}
        </div>

        {#if separator}
            <hr class="divide-y divide-gray-100 my-8"/>
        {/if}
    </div>
{/if}
