<script>
    import I18n from '@js/modules/translations';

    import walkIcon from '@js/components/icons/walkIcon';
    import Input from '@js/components/themes/Input.svelte';
    import Icon from '@js/components/themes/Icon.svelte';

    const {
        name,
        label = I18n.t('js.ride.model.name'),
        defaultValue
    } = $props();
</script>

<Input type="text"
       class="mx-auto w-full md:w-4/5"
       inputClass="py-4"
       name={name}
       label={label}
       defaultValue={defaultValue}>
    {#snippet iconRender()}
    <Icon class="shrink-0 w-6 h-6"
          icon={walkIcon}/>
    {/snippet}
</Input>
