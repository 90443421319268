<script>
    import I18n from '@js/modules/translations';

    import Textarea from '@js/components/themes/Textarea.svelte';

    const {
        name,
        label = I18n.t('js.ride.model.description'),
        defaultValue
    } = $props();
</script>

<Textarea class="mx-auto w-full md:w-4/5"
          name={name}
          label={label}
          defaultValue={defaultValue}/>
