import api from '@js/actions/api';

import {
    maxWidthUpload,
    maxHeightUpload
} from '@js/components/modules/constants';

export const resizeImage = (originalFile, callback) => {
    let maxWidth = maxWidthUpload;
    let maxHeight = maxHeightUpload;
    const fileName = originalFile.name;
    const reader = new FileReader();
    reader.readAsDataURL(originalFile);
    reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;
        image.onload = () => {
            if (image.width < maxWidth && image.height < maxHeight) {
                callback(originalFile);
                return;
            }

            let ratio;
            if (image.height > image.width) {
                ratio = maxHeight / image.height;
                maxWidth = image.width * ratio;
            } else {
                ratio = maxWidth / image.width;
                maxHeight = image.height * ratio;
            }

            const elem = document.createElement('canvas');
            elem.width = maxWidth;
            elem.height = maxHeight;
            const ctx = elem.getContext('2d');
            // img.width and img.height will contain the original dimensions
            ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

            ctx.canvas.toBlob((blob) => {
                const compressedFile = new File([blob], fileName, {
                    type: 'image/jpeg',
                    lastModified: Date.now()
                });

                callback(compressedFile);
            }, 'image/jpeg', 1);
        };
    };
    reader.onerror = (error) => console.error(error);
};

export const uploadImages = (images, params, doneCallback) => {
    const uploads = [];

    Object.entries(images)
        .forEach(([, value]) => {
            resizeImage(value, (compressedFile) => {
                const formData = new FormData();

                formData.append('upload[file]', compressedFile);

                Object.entries(params)
                    .forEach(([key, uploadValue]) => {
                        formData.append(`upload[${key}]`, uploadValue);
                    });

                uploads.push(api.post('/api/v1/uploader', formData, true));

                if (images.length === uploads.length) {
                    doneCallback(uploads);
                }
            });
        });
};

export const uploadFile = (uploadUrl, file, fileParamName, params) => {
    const formData = new FormData();

    formData.append(`upload[${fileParamName}]`, file);

    Object.entries(params)
        .forEach(([key, uploadValue]) => {
            formData.append(`upload[${key}]`, uploadValue);
        });

    return api.post(uploadUrl, formData, true);
};

export const updateImage = (imageId, imageData) => (
    api.update(`/api/v1/uploader/${imageId}`, imageData)
);

export const deleteImage = (imageId, options = {}) => (
    api.delete(`/api/v1/uploader/${imageId}`, options)
);

export const deleteFile = (deleteUrl, imageId) => (
    api.delete(deleteUrl, {
        id: imageId
    })
);
