<script>
    import * as Utils from '@js/modules/utils';
    import I18n from '@js/modules/translations';
    import History from '@js/modules/history';

    import {mapStore} from '@js/stores/mapStore';

    import Autocompletion from '@js/components/search/Autocompletion.svelte';

    import PWAManager from '@js/modules/pwaManager';
    import AnalyticsService from '@js/modules/analyticsService';

    const LOCATION_HISTORY_NAME = 'home_location_data';

    const initialLocationData = History.getPreviousState(LOCATION_HISTORY_NAME, {useUrlParams: true});

    const _saveHistory = (selectedLocation) => {
        const locationParams = {
            query: selectedLocation.query,
            value: selectedLocation.value,
            type: selectedLocation.type,
            placeType: selectedLocation.placeType,
            bbox: !!selectedLocation.bbox ? [...selectedLocation.bbox] : null,
            center: !!selectedLocation.center ? [...selectedLocation.center] : null
        };

        History.saveCurrentState(
            {
                [LOCATION_HISTORY_NAME]: locationParams
            },
            {
                query: selectedLocation.query,
                center: selectedLocation.center
            }
        );
    };

    const _onHistoryChange = (onHistoryCallback) => {
        History.onStateChange((newState) => {
            if (newState[LOCATION_HISTORY_NAME]) {
                onHistoryCallback(newState[LOCATION_HISTORY_NAME]);
            }
        });
    };

    const _handleSubmit = (selectedLocation, {track = true} = {}) => {
        if (selectedLocation?.value) {
            if (track) {
                AnalyticsService.trackHomeShowPlaceSelected();
                AnalyticsService.trackSearch('Home page', undefined, `${selectedLocation.value} (${selectedLocation.placeType})`, 1);
            }

            setTimeout(() => Utils.scrollTo('home-map'), 100);
        }

        mapStore.mapLocation = selectedLocation;

        _saveHistory(selectedLocation);
    };

    _onHistoryChange((newLocationData) => {
        _handleSubmit(newLocationData, {track: false});
    });
</script>

<div class="home-search-form">
    <form id="global-search"
          class="search"
          accept-charset="UTF-8"
          autocomplete="off"
          novalidate="novalidate"
          onsubmit={_handleSubmit}>
        <Autocompletion id="home-search"
                        placeholder={I18n.t('js.views.home.search.placeholder')}
                        searchFeatures={['location']}
                        autoFocus={PWAManager.getPWADisplayMode() === PWAManager.MODE.BROWSER}
                        initialValue={initialLocationData?.value || initialLocationData?.query}
                        onSubmit={_handleSubmit}/>
    </form>
</div>
