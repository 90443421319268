
import ExifReader from 'exifreader';


export default function CoordinatesFromPicture(file, successCallback, errorCallback) {
    ExifReader.load(file)
        .then((tags) => {
            let lat = tags.GPSLatitude?.value ?? tags.GPSLatitude;
            let lng = tags.GPSLongitude?.value ?? tags.GPSLongitude;

            if (tags.GPSLatitude?.description && tags.GPSLongitude?.description) {
                successCallback({
                    lat: parseFloat(tags.GPSLatitude.description),
                    lng: parseFloat(tags.GPSLongitude.description)
                });
            } else if (lat && lng) {
                lat = Array.isArray(lat) && Array.isArray(lat[0]) ? lat.map((l) => l[0] / l[1]) : lat;
                lng = Array.isArray(lng) && Array.isArray(lng[0]) ? lng.map((l) => l[0] / l[1]) : lng;

                //Convert coordinates to WGS84 decimal
                const latRef = tags.GPSLatitudeRef.value || tags.GPSLatitudeRef || 'N';
                const lonRef = tags.GPSLongitudeRef.value || tags.GPSLatitudeRef || 'W';
                lat = (lat[0] + lat[1] / 60 + lat[2] / 3600) * (latRef === 'N' ? 1 : -1);
                lng = (lng[0] + lng[1] / 60 + lng[2] / 3600) * (lonRef === 'W' ? -1 : 1);

                successCallback({
                    lat: lat,
                    lng: lng
                });
            } else if (successCallback) {
                successCallback(false);
            }
        })
        .catch(errorCallback);
}
