<script>
    import {Dropdown, Tooltip} from 'flowbite';

    import * as Utils from '@js/modules/utils';
    import I18n from '@js/modules/translations';

    import {showProductModal} from '@js/actions/basketActions';

    import Icon from '@js/components/themes/Icon.svelte';
    import runIcon from '@js/components/icons/runIcon';
    import hikeIcon from '@js/components/icons/hikeIcon';
    import bikeIcon from '@js/components/icons/bikeIcon';
    import bicycleIcon from '@js/components/icons/bicycleIcon';
    import carIcon from '@js/components/icons/carIcon';

    import {RouteComputationTypes} from '@js/components/modules/constants';

    const ROUTE_TYPE_ICON = {
        foot: runIcon,
        hike: hikeIcon,
        bike: bikeIcon,
        mtb: bicycleIcon,
        car: carIcon
    };

    const {
        defaultRouteType = 'hike',
        isSnapToRoadChecked = true,
        isRouteTypeAvailable = false,
        // isPoiModeChecked = false,
        onSnapToRoadChange,
        onRouteTypeChange,
        onBackToHomeClick,
        onUndoClick,
        onClearClick
    } = $props();

    let routeTypeDropdown;

    let isSnapToRoad = $state(isSnapToRoadChecked);
    let routeType = $state(defaultRouteType);

    const _onSnapToRoadChange = (event) => {
        isSnapToRoad = event.target.checked;

        onSnapToRoadChange(isSnapToRoad);
    };

    const _onRouteTypeChange = (newRouteType) => {
        routeType = newRouteType;
        routeTypeDropdown.hide();

        onRouteTypeChange(routeType);
    };

    const _handleRouteTypeClick = (event) => {
        event.preventDefault();

        if (isRouteTypeAvailable) {
            return;
        }

        showProductModal('route_type');
    };

    const routeTypeDropdownAction = (rideDropdownElement) => {
        $effect(() => {
            const rideDropdownTrigger = document.getElementById('ride-route-types');

            routeTypeDropdown = new Dropdown(rideDropdownElement, rideDropdownTrigger);

            if (!isRouteTypeAvailable) {
                const rideTooltipElement = document.getElementById('tooltip-route-type');
                const rideTooltipTrigger = document.getElementById('ride-route-types-tooltip');
                new Tooltip(rideTooltipElement, rideTooltipTrigger, {
                    placement: 'bottom',
                    triggerType: 'hover'
                });
            }
        });
    };
</script>

<div class="map-navigation-container">
    <div class="map-navigation-snap-to-road">
        <label class="flex flex-col items-center justify-center py-1 px-1 text-center text-primary-default cursor-pointer">
            <input type="checkbox"
                   id="snap-to-roads"
                   class="sr-only peer"
                   checked={isSnapToRoad}
                   onchange={_onSnapToRoadChange}
                   value="">
            <span class="relative w-8 h-3 mt-1 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-primary-500 dark:peer-focus:ring-primary-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:h-4 peer-checked:after:w-4 peer-checked:after:start-[4px] peer-checked:after:top-[-2px] after:content-[''] after:absolute after:top-[0px] after:start-[0px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-gray-600 peer-checked:bg-primary-600"></span>

            <span class="button-label">
                {I18n.t('js.maps.toolbar.snap_to_road.title')}
            </span>
        </label>
    </div>

    <div id="route-type-element"
         class="map-navigation-route-type">
        <div id="ride-route-types-tooltip"
             class="w-34 mx-auto"
             data-tooltip-target={isRouteTypeAvailable ? undefined : 'tooltip-route-type'}
             data-tooltip-placement="bottom">
            <div class="flex">
                <button type="button"
                        id="ride-route-types"
                        class="button-label flex-shrink-0 inline-flex items-center py-1.5 px-2 font-medium text-center text-primary-default bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700 dark:text-white dark:border-gray-600"
                        data-dropdown-toggle="route-type-dropdown"
                        onclick={_handleRouteTypeClick}>
                    <Icon class="h-4 w-4 me-2"
                          icon={ROUTE_TYPE_ICON[routeType]}/>
                    {I18n.t(`js.maps.toolbar.route_type.types.${routeType}`)}
                    <svg class="w-2.5 h-2.5 ms-2.5"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 10 6">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="m1 1 4 4 4-4"/>
                    </svg>
                </button>

                <div use:routeTypeDropdownAction
                     id="route-type-dropdown"
                     class="hidden w-32 bg-white divide-y divide-gray-100 rounded-lg shadow z-dropdown dark:bg-gray-700">
                    <ul class="py-1 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="ride-route-types">
                        {#each Object.entries(Utils.sortObjectByKeys(I18n.t('js.maps.toolbar.route_type.types'), RouteComputationTypes)) as [type, routeName]}
                            <li>
                                <button type="button"
                                        class="inline-flex w-full px-2 py-1 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                        class:bg-primary-200={type === routeType}
                                        onclick={() => _onRouteTypeChange(type)}>
                                        <span class="inline-flex items-center">
                                            <Icon class="h-4 w-4 me-3"
                                                  icon={ROUTE_TYPE_ICON[type]}/>
                                            {routeName}
                                        </span>
                                </button>
                            </li>
                        {/each}
                    </ul>
                </div>
            </div>
        </div>

        {#if !isRouteTypeAvailable}
            <div id="tooltip-route-type"
                 role="tooltip"
                 class="absolute invisible inline-block px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip z-tooltip dark:bg-gray-700">
                {I18n.t('js.maps.toolbar.route_type.tooltip')}
                <div class="tooltip-arrow"
                     data-popper-arrow></div>
            </div>
        {/if}
    </div>
</div>

<div class="map-navigation-control-container">
    <div class="map-navigation-control">
        <button id="undo-control"
                class="control-button"
                type="button"
                title={I18n.t('js.maps.toolbar.undo')}
                onclick={onUndoClick}>
            <svg class="control-button-icon inline-flex w-5 h-5 my-1"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill="currentColor">
                <path d="M12.5,8C9.85,8 7.45,9 5.6,10.6L2,7V16H11L7.38,12.38C8.77,11.22 10.54,10.5 12.5,10.5C16.04,10.5 19.05,12.81 20.1,16L22.47,15.22C21.08,11.03 17.15,8 12.5,8Z"/>
            </svg>
            <span class="button-label">
                {I18n.t('js.maps.toolbar.undo')}
            </span>
        </button>
    </div>

    <div class="map-navigation-back-to-home">
        <button id="back-to-home"
                class="control-button"
                type="button"
                title={I18n.t('js.maps.toolbar.back_to_home')}
                onclick={onBackToHomeClick}>
            <svg class="control-button-icon inline-flex w-5 h-5 my-1"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill="currentColor">
                <path d="M12,5V1L7,6L12,11V7A6,6 0 0,1 18,13A6,6 0 0,1 12,19A6,6 0 0,1 6,13H4A8,8 0 0,0 12,21A8,8 0 0,0 20,13A8,8 0 0,0 12,5Z"/>
            </svg>
            <span class="button-label">
                {I18n.t('js.maps.toolbar.back_to_home')}
            </span>
        </button>
    </div>

    <div class="map-navigation-control">
        <button id="clear-control"
                class="control-button"
                type="button"
                title={I18n.t('js.maps.toolbar.clear')}
                onclick={onClearClick}>
            <svg class="control-button-icon inline-flex w-5 h-5 my-1"
                 xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 24 24"
                 fill="currentColor">
                <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
            </svg>
            <span class="button-label">
                {I18n.t('js.maps.toolbar.clear')}
            </span>
        </button>
    </div>

    <!--         PATCHME: add poi mode -->
    <!--        <div id="map-navigation-poi-mode"-->
    <!--             class="map-navigation-poi-mode">-->
    <!--            <FormControlLabel class="map-navigation-snap"-->
    <!--                              classes={{-->
    <!--                                                      label: 'map-navigation-label'-->
    <!--                                                  }}-->
    <!--                              control={<Switch id="switch-poi-mode"-->
    <!--                                                                   class="map-navigation-switch"-->
    <!--                                                                   color="primary"-->
    <!--                                                                   size="small"-->
    <!--                                                                   defaultChecked={isPoiModeChecked}/>}-->
    <!--                              label={I18n.t('js.maps.toolbar.poi_mode')}-->
    <!--                              labelPlacement="bottom"/>-->
    <!--        </div>-->
</div>
