<script>
    import {tweened} from 'svelte/motion';
    import {cubicOut} from 'svelte/easing';

    import I18n from '@js/modules/translations';

    import Computation from '@js/modules/computation';

    import {userStore} from '@js/stores/userStore';
    import {mapStore} from '@js/stores/mapStore';

    const distanceProgress = $state(tweened(mapStore.currentDistance, {
        duration: 800,
        easing: cubicOut
    }));

    $effect(() => {
        distanceProgress.set($mapStore.currentDistance);
    });
</script>

<div id="map-distance"
     class="map-distance">
    <span class="map-distance-text">
        {I18n.t('js.maps.distance.content')}
    </span>

    <span class="map-distance-value"
          class:map-distance-present={$mapStore.currentDistance}>
        {Computation.formatDistance($distanceProgress, true, false, 3, $userStore.unit)}
    </span>
</div>
