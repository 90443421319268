<script>
    import {tweened} from 'svelte/motion';
    import {cubicOut} from 'svelte/easing';

    import * as Utils from '@js/modules/utils';

    import I18n from '@js/modules/translations';

    import {userStore} from '@js/stores/userStore';

    import {fetchStats} from '@js/actions/statsActions';

    let computedDistance = $state();
    let distance = $state();

    fetchStats()
        .then((response) => {
            if (Utils.isEmpty(response)) {
                return;
            }

            computedDistance = response.computed_distance;

            distance = tweened(parseInt(computedDistance || 0), {
                duration: 800,
                easing: cubicOut
            });
        });

    const setRandomInterval = (intervalFunction, minDelay, maxDelay) => {
        let timeout = null;

        const runInterval = () => {
            const timeoutFunction = () => {
                intervalFunction();
                runInterval();
            };

            const delay = Math.floor(Math.random() * (maxDelay - minDelay + 1)) + minDelay;

            timeout = setTimeout(timeoutFunction, delay);
        };

        runInterval();

        return {
            clear() {
                clearTimeout(timeout);
            }
        };
    };

    const distanceDisplay = $derived(new Intl.NumberFormat('fr', {
        style: 'unit',
        unit: $userStore.unit === 'km' ? 'kilometer' : 'mile',
        roundingMode: 'ceil'
    }).format(parseInt($distance / ($userStore.unit === 'km' ? 1000 : 1609))));

    $effect(() => {
        if (computedDistance) {
            setRandomInterval(() => distance.set($distance * 1.0005), 1000, 4000);
        }
    });
</script>

{#if computedDistance}
    <div class="text-xs text-gray-500">
        {I18n.t('js.views.home.computed_distance', {distance: distanceDisplay})}
    </div>
{/if}
