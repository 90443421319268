<script>
    import {fade} from 'svelte/transition';

    import classnames from 'classnames';

    import * as Utils from '@js/modules/utils';
    import I18n from '@js/modules/translations';

    import MapRequest from '@js/modules/maps/mapRequest';

    import {fetchAutocomplete} from '@js/actions/autocompleteActions';

    import Icon from '@js/components/themes/Icon.svelte';
    import Bounce from '@js/components/themes/Bounce.svelte';

    import closeIcon from '@js/components/icons/closeIcon';
    import searchIcon from '@js/components/icons/searchIcon';

    import {autocompleteLimit, maxSearchRate} from '@js/components/modules/constants';

    import('@css/components/autocompletion.scss');

    let {
        id,
        class: className,
        placeholder,
        searchButton = I18n.t('js.helpers.buttons.search'),
        searchFeatures, // all (by default), location, ride, tag, user
        searchFilters = {},
        initialValue,
        searchValue = $bindable({value: ''}),
        minQueryLength = 2,
        rideResultsFirst = false,
        autoFocus = false,
        useFirstQueryOnEnter = true,
        onSubmit
    } = $props();

    const LIMIT_SOURCE_RESULT = 3;

    let searchInput = $state();
    let isFetching = $state(false);
    let autocompleteResults = $state([]);
    let highlightedIndex = $state(null);

    if (initialValue) {
        searchValue.value = initialValue;
    }

    let _proximity = {
        lat: parseFloat(window.userLatitude) || 0,
        lng: parseFloat(window.userLongitude) || 0
    };
    if (!_proximity.lat) {
        const currentCountry = window.supportedCountries.find((country) => country.code === window.countryCode);

        _proximity = {
            lat: currentCountry.map_center[0],
            lng: currentCountry.map_center[1]
        };
    }

    let _autocompleteRequest;
    const _autocompleteRequestAbort = {};
    let _locationRequest;
    const _locationRequestAbort = {};

    let enterPressed = false;

    const highlightQuery = (currentValue) => {
        let newValue = currentValue;

        const replaceIndexes = [];
        try {
            currentValue.normalize('NFD')
                .trim()
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/[\s-_+@#$%^&*,;]/g, ' ')
                .replace(new RegExp(searchValue.value, 'gi'), (match, startIndex) => {
                    replaceIndexes.push({
                        startIndex,
                        match
                    });
                });
        } catch (_) {
            // Do nothing
        }

        if (replaceIndexes.length) {
            replaceIndexes.forEach((indexData) => {
                let replacedValue = currentValue.substring(indexData.startIndex, indexData.startIndex + indexData.match.length);
                if (replacedValue.charAt(0)
                    .toUpperCase() === replacedValue.charAt(0)) {
                    replacedValue = replacedValue.charAt(0)
                        .toUpperCase() + replacedValue.slice(1);
                }
                newValue = currentValue.replaceAt(indexData.startIndex, indexData.match.length, `<strong>${replacedValue}</strong>`);
            });
        }

        return newValue;
    };

    const _onAutocompleteResult = (results) => {
        let newResults = [];

        if (results.tags?.length) {
            newResults = newResults.concat(
                results.tags
                    .map((tag) => ({
                        uniqueId: `tag-${tag.id}`,
                        label: I18n.t('js.autocomplete.tag'),
                        type: 'tag',
                        id: tag.id,
                        key: tag.name,
                        value: tag.name
                    }))
                    .limit(LIMIT_SOURCE_RESULT)
            );
        }

        if (results.rides?.length) {
            newResults = newResults.concat(
                results.rides
                    .map((ride) => ({
                        uniqueId: `ride-${ride.id}`,
                        label: I18n.t(`js.autocomplete.ride.${ride.rideType}`),
                        type: 'ride',
                        id: ride.id,
                        key: ride.name,
                        value: ride.name,
                        rideType: ride.rideType
                    }))
                    .limit(LIMIT_SOURCE_RESULT)
            );
        }

        // if (results.users?.length) {
        //     newResults.userResults = results.users;
        // }

        return newResults;
    };

    const _onLocationResult = (features) => {
        let newResults = [];

        if (features?.length) {
            newResults = newResults.concat(
                features.map((location) => ({
                    uniqueId: `location-${location.fullName.replace(/ /g, '_')}`,
                    label: ['peak', 'poi', 'other'].includes(location.placeType) ? I18n.t(`js.autocomplete.location.${location.placeType}`) : undefined,
                    type: 'location',
                    key: location.fullName,
                    query: location.label,
                    value: location.fullName,
                    placeType: location.placeType,
                    center: location.center,
                    bbox: location.bbox
                }))
            );
        }

        return newResults;
    };

    const _autocomplete = Utils.debounce((query, proximity) => {
        if (query.length < minQueryLength) {
            return;
        }

        const requests = [];

        if (!searchFeatures || searchFeatures.includes('location')) {
            if (_locationRequestAbort?.signal) {
                _locationRequestAbort.signal.abort();
            }

            _locationRequest = MapRequest.locationSearch(query, {
                locale: I18n.locale,
                proximity: proximity,
                limit: autocompleteLimit,
                autocomplete: true,
                sortByPlaceType: true,
                sortByMatchingName: true,
                sortByLocaleCountry: true
            }, {
                abort: _locationRequestAbort
            });

            requests.push(_locationRequest);
        }

        if (!searchFeatures || searchFeatures.includes('ride') || searchFeatures.includes('tag') || searchFeatures.includes('user')) {
            if (_autocompleteRequestAbort?.signal) {
                _autocompleteRequestAbort.signal.abort();
            }

            const selectedTypes = [];
            if (searchFeatures) {
                if (searchFeatures.includes('ride')) {
                    selectedTypes.push('ride');
                }
                if (searchFeatures.includes('tag')) {
                    selectedTypes.push('tag');
                }
                if (searchFeatures.includes('user')) {
                    selectedTypes.push('user');
                }
            }

            _autocompleteRequest = fetchAutocomplete({
                selectedTypes,
                query: searchValue.value,
                limit: autocompleteLimit,
                ...searchFilters
            }, {
                abort: _autocompleteRequestAbort
            });

            requests.push(_autocompleteRequest);
        }

        Promise.allSettled(requests)
            .then((results) => {
                isFetching = false;

                let newResults = [];

                results.forEach((result, promiseIndex) => {
                    if (result.status === 'fulfilled' && result.value) {
                        if (promiseIndex === 0) {
                            newResults = newResults.concat(_onLocationResult(result.value));
                        } else {
                            newResults = newResults.concat(_onAutocompleteResult(result.value));
                        }
                        // } else if (result.status === 'rejected') {
                        //     pushError(result.reason);
                    }
                });

                if (rideResultsFirst) {
                    newResults = newResults.sort((a, b) => {
                        if (b.type === 'ride') {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
                }

                autocompleteResults = newResults;

                if (enterPressed) {
                    enterPressed = false;

                    setInputVal(autocompleteResults[highlightedIndex]);
                    closeAutocompletion();
                }
            });
    }, maxSearchRate);

    const filterResults = () => {
        isFetching = true;

        if (searchValue) {
            _autocomplete(searchValue.value, _proximity);
        } else {
            autocompleteResults = [];
            highlightedIndex = null;
        }
    };

    const clickOutside = (element, callbackFunction) => {
        function onClick(event) {
            if (!element.contains(event.target)) {
                callbackFunction();
            }
        }

        document.body.addEventListener('click', onClick);

        return {
            update(newCallbackFunction) {
                callbackFunction = newCallbackFunction;
            },
            destroy() {
                document.body.removeEventListener('click', onClick);
            }
        };
    };

    const clearInput = (event) => {
        event.preventDefault();

        isFetching = false;
        searchValue = {value: ''};
        searchInput.focus();

        onSubmit({...searchValue});
    };

    const closeAutocompletion = () => {
        autocompleteResults = [];
        highlightedIndex = null;
        document.getElementById(id)
            .focus();
    };

    const setInputVal = (selectedResult) => {
        isFetching = false;
        enterPressed = false;

        searchValue = selectedResult || searchValue;
        closeAutocompletion();

        onSubmit({...searchValue});
    };

    const submit = () => {
        if (useFirstQueryOnEnter) {
            if (highlightedIndex === null) {
                highlightedIndex = 0;
            }

            // Wait fetching is finish to use the first entry as result
            if (!isFetching) {
                setInputVal(autocompleteResults[highlightedIndex]);

                closeAutocompletion();

                _autocompleteRequestAbort.signal?.abort();
                _locationRequestAbort.signal?.abort();
            }
        } else {
            _autocompleteRequestAbort.signal?.abort();
            _locationRequestAbort.signal?.abort();

            setInputVal();
        }
    };

    const navigateList = (event) => {
        if (event.key === 'ArrowDown' && highlightedIndex <= autocompleteResults.length - 1) {
            highlightedIndex === null ? highlightedIndex = 0 : highlightedIndex += 1;
        } else if (event.key === 'ArrowUp' && highlightedIndex !== null) {
            highlightedIndex === 0 ? highlightedIndex = autocompleteResults.length - 1 : highlightedIndex -= 1;
        } else if (event.key === 'Enter') {
            event.preventDefault();

            enterPressed = true;

            submit();
        } else if (event.key === 'Escape') {
            closeAutocompletion();
        } else if (!searchValue.value && isFetching) {
            isFetching = false;
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        enterPressed = true;

        submit();
    };

    $effect(() => {
        return () => {
            _autocompleteRequestAbort.signal?.abort();
            _locationRequestAbort.signal?.abort();
        };
    });
</script>

<div class={classnames('relative text-left', className)}>
    <label for={id}
           class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
        {searchButton}
    </label>

    <div class="hidden md:flex absolute inset-y-0 start-0 items-center ps-3 pointer-events-none">
        <Icon class="w-6 h-6 text-gray-500 dark:text-gray-400"
              icon={searchIcon}/>
    </div>

    <div class="autocompletion-input">
        <input type="search"
               id={id}
               class="block w-full p-4 ps-4 md:ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
               placeholder={placeholder}
               required
               autofocus={autoFocus}
               onkeydown={navigateList}
               oninput={filterResults}
               bind:this={searchInput}
               bind:value={searchValue.value}>
    </div>

    <div class="absolute end-2.5 bottom-2.5 flex items-center justify-center">
        {#if isFetching}
            <Bounce color="primary"/>
        {/if}

        {#if searchValue.value && !isFetching}
            <a class="ml-2 md:ml-4 cursor-pointer"
               href="#clear-input"
               onclick={clearInput}>
                <Icon class="w-6 h-6"
                      icon={closeIcon}/>
            </a>
        {/if}

        <button type="submit"
                class="ml-2 md:ml-4 text-white bg-primary-700 hover:bg-primary-800 focus:outline-none focus:ring-2 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onclick={handleSubmit}>
            <span class="block md:hidden">
                <Icon class="w-5 h-5"
                      icon={searchIcon}/>
            </span>
            <span class="hidden md:block">
                {searchButton}
            </span>
        </button>
    </div>

    {#if autocompleteResults.length > 0}
        <ul style="width: 88%"
            class="absolute ms-7 ml-4 mt-px bg-white border border-gray-300 z-dropdown rounded-md"
            use:clickOutside={closeAutocompletion}
            transition:fade={{ duration: 300 }}>
            {#each autocompleteResults as autocompleteResult, i (autocompleteResult.uniqueId)}
                <li class="group list-none p-1.5 text-sm rounded-md cursor-pointer hover:text-white hover:bg-primary-800 active:text-white active:bg-primary-600"
                    class:text-white={i === highlightedIndex}
                    class:bg-primary-500={i === highlightedIndex}
                    onclick={() => setInputVal(autocompleteResult)}>
                    {@html highlightQuery(autocompleteResult.value)}

                    {#if autocompleteResult.label}
                        <span style="margin-left: 5px; margin-bottom: 3px; font-size: 0.75rem; line-height: 0.9rem;"
                              class="group-hover:text-white inline-flex items-center px-2.5 py-0.5 text-primary-500 font-medium align-middle rounded border border-primary-400"
                              class:text-white={i === highlightedIndex}>
                            {autocompleteResult.label}
                        </span>
                    {/if}
                </li>
            {/each}
        </ul>
    {/if}
</div>
