import api from '@js/actions/api';

import {basketStore} from '@js/stores/basketStore';

import {trackLimitReached} from '@js/actions/trackingActions';

import AnalyticsService from '@js/modules/analyticsService';


export const addToBasket = (productId, origin) => (
    api.post('/api/v1/baskets', {
        basket: {
            products: [{
                productId
            }],
            origin
        }
    })
);

export const fetchProduct = (productId, options = {}, requestOptions = {}) => (
    api.get(`/api/v1/products/${productId}`, {
        ...options
    }, requestOptions)
);

export const chargeBasket = (basketId, paymentData, options = {}) => (
    api.post('/api/v1/orders', {
        basketId: basketId,
        order: paymentData,
        ...options
    })
);

export const showProductModal = (requiredFunctionality) => {
    AnalyticsService.trackProductModalShow(requiredFunctionality);

    trackLimitReached(requiredFunctionality);

    basketStore.productRequiredFunctionality = requiredFunctionality;
    if (basketStore.productModal && typeof basketStore.productModal.show === 'function') {
        basketStore.productModal.show();
    }
};

export const hideProductModal = () => {
    basketStore.productRequiredFunctionality = undefined;
    if (basketStore.productModal && typeof basketStore.productModal.hide === 'function') {
        basketStore.productModal.hide();
    }
};
