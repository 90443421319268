import {writable} from 'svelte/store';

const initState = {
    map: null,

    mapLocation: undefined,

    currentDistance: 0,

    currentDuration: 0,
    durationComputed: false,

    currentBreakTime: 0,

    currentSpeed: 0,
    speedComputed: true,

    currentRouteType: window.currentUserRideType || 'running'
};

function MapStore(state = initState) {
    const {
        subscribe,
        // set,
        update
    } = writable(
        state
    );

    function updateState(data) {
        Object.entries(data)
            .forEach(([dataName, newData]) => {
                update((storeState) => {
                    storeState[dataName] = newData;

                    return storeState;
                });
            });
    }

    return {
        subscribe,
        get state() {
            return state;
        },
        get map() {
            return state.map;
        },
        set map(map) {
            updateState({map});
        },
        get mapLocation() {
            return state.mapLocation;
        },
        set mapLocation(mapLocation) {
            updateState({mapLocation});
        },
        get currentDistance() {
            return state.currentDistance;
        },
        set currentDistance(currentDistance) {
            updateState({currentDistance});
        },
        get currentDuration() {
            return state.currentDuration;
        },
        set currentDuration(currentDuration) {
            updateState({currentDuration});
        },
        get durationComputed() {
            return state.durationComputed;
        },
        set durationComputed(durationComputed) {
            updateState({durationComputed});
        },
        get currentBreakTime() {
            return state.currentBreakTime;
        },
        set currentBreakTime(currentBreakTime) {
            updateState({currentBreakTime});
        },
        get currentSpeed() {
            return state.currentSpeed;
        },
        set currentSpeed(currentSpeed) {
            updateState({currentSpeed});
        },
        get speedComputed() {
            return state.speedComputed;
        },
        set speedComputed(speedComputed) {
            updateState({speedComputed});
        },
        get currentRouteType() {
            return state.currentRouteType;
        },
        set currentRouteType(currentRouteType) {
            updateState({currentRouteType});
        }
    };
}

export const mapStore = MapStore();
