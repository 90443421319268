import api from '@js/actions/api';

import {handleJsonResponse} from '@js/actions/jsonFormatter';

export const externalFetch = (url, params = {}, requestOptions = {}) => {
    return api
        .get(url, params, {external: true, ...requestOptions});
};

export const updateFetch = (url, params = {}, serializedData = false, isData = false) => {
    return api
        .update(url, params, isData)
        .then((response) => {
            if (serializedData) {
                return handleJsonResponse(response);
            } else {
                return response;
            }
        });
};
