import Swal from 'sweetalert2';

import * as Utils from '@js/modules/utils';
import I18n from '@js/modules/translations';
import Computation from '@js/modules/computation';

import Notification from '@js/components/layouts/notification';


export const checkRideErrors = (rideData) => {
    const formFields = {};
    for (const [fieldName, fieldValue] of rideData.entries()) {
        formFields[fieldName] = fieldValue;
    }

    const rideFieldErrors = {};

    if (Utils.isEmpty(formFields['ride[distance]']) || parseFloat(formFields['ride[distance]']) < parseFloat(window.settings.ride_min_distance)) {
        rideFieldErrors.distance = I18n.t('js.ride.errors.distance', {
            min: Computation.formatDistance(window.settings.ride_min_distance, true, false),
            max: Computation.formatDistance(window.settings.ride_max_distance, true)
        });
    }

    if (Utils.isEmpty(formFields['ride[duration]']) && Utils.isEmpty(formFields['ride[speed]'])) {
        rideFieldErrors.duration = I18n.t('js.ride.errors.duration', {
            min: Computation.formatDuration(window.settings.ride_min_duration, true, true, false),
            max: Computation.formatDuration(window.settings.ride_max_duration, false)
        });
        rideFieldErrors.speed = I18n.t('js.ride.errors.speed', {
            min: Computation.formatSpeed(window.settings.ride_min_speed),
            max: Computation.formatSpeed(window.settings.ride_max_speed, false)
        });
    }

    if (Utils.isEmpty(formFields['ride[name]']) || formFields['ride[name]'].length < parseInt(window.settings.ride_name_min_length)) {
        rideFieldErrors.name = I18n.t('js.ride.errors.name', {
            min: window.settings.ride_name_min_length,
            max: window.settings.ride_name_max_length
        });
    }

    if (Object.keys(formFields)
        .includes('ride[planned_at]') && Utils.isEmpty(formFields['ride[planned_at]'])) {
        rideFieldErrors.planned_at = I18n.t('js.ride.errors.planned_at');
    }

    return Utils.isPresent(rideFieldErrors) ? rideFieldErrors : false;
};

export const buildRideFormData = (form, parameters) => {
    const {
        name,
        rideType,
        visibility,
        distance,
        duration,
        durationComputed,
        speed,
        speedComputed,
        isMapEdited,
        points,
        staticMap,
        planned,
        plannedAt,
        plannedPoiId,
        ridePois
    } = parameters || {};

    const rideData = new FormData(form);

    if (visibility) {
        rideData.append('ride[visibility]', visibility);
    }

    rideData.append('ride[distance]', distance);

    rideData.append('ride[duration_computed]', durationComputed);
    if (speedComputed) {
        rideData.append('ride[duration]', duration);
    }

    rideData.append('ride[speed_computed]', speedComputed);
    if (durationComputed) {
        rideData.append('ride[speed]', speed);
    }

    if (window.unit === 'mi') {
        rideData.append('ride[speed]', Computation.speedToUnit(speed, 'kmh'));
    }

    if (planned) {
        rideData.append('ride[planned]', true);

        if (name) {
            rideData.append('ride[name]', name);
        }
        if (rideType) {
            rideData.append('ride[ride_type]', rideType);
        }
        if (plannedAt) {
            rideData.append('ride[planned_at]', plannedAt);
        }
    }
    if (plannedPoiId) {
        rideData.append('ride[planned_poi_id]', plannedPoiId);
    }

    if (isMapEdited && points?.length) {
        rideData.append('ride[map_edited]', true);
        rideData.append('ride[map_points]', points);
        if (staticMap) {
            rideData.append('ride[static_map]', staticMap);
        }
    }

    if (ridePois?.length) {
        ridePois.map((ridePoi) => Object.keys(ridePoi)
            .forEach((poiDataName) => rideData.append(`ride[pois][][${poiDataName}]`, ridePoi[poiDataName])));
    }

    const rideErrors = checkRideErrors(rideData);

    if (rideErrors) {
        Notification.error(I18n.t('js.ride.errors.message'));

        return {
            errors: true,
            rideErrors
        };
    }

    return rideData;
};

export const processRide = (submissionResponse, checkJobProgress) => {
    const rideId = submissionResponse?.ride?.id;

    if (!rideId) {
        return false;
    }

    let timerInterval;

    return Swal.fire({
        title: I18n.t('js.ride.common.loading_process'),
        html: '<div id="ride-message"></div>',
        showCancelButton: false,
        allowOutsideClick: false,
        didOpen: () => {
            Swal.showLoading();

            timerInterval = setInterval(() => {
                checkJobProgress(rideId, (response) => {
                    if (response) {
                        const {
                            processingMessage,
                            // currentStep,
                            totalSteps,
                            rideLink
                        } = response;

                        const messageContainer = Swal.getHtmlContainer();
                        if (!messageContainer) {
                            return;
                        }

                        messageContainer.querySelector('#ride-message');

                        if (processingMessage) {
                            messageContainer.textContent = processingMessage;
                        }

                        if (rideLink) {
                            clearInterval(timerInterval);
                            // setTimeout(() => Swal.close(), 1000);
                            setTimeout(() => window.location = rideLink, 300);
                        } else if (totalSteps === 0) {
                            // Problem when processing ride data
                            clearInterval(timerInterval);
                            Swal.hideLoading();
                        }
                    } else {
                        Swal.hideLoading();
                    }
                });
            }, 400);
        },
        willClose: () => {
            clearInterval(timerInterval);
        }
    });
};

export const checkPoiErrors = (poiData, plan = false) => {
    const poiFieldErrors = {};

    if (Utils.isEmpty(poiData.get('poi[title]'))) {
        poiFieldErrors.title = I18n.t('js.poi.errors.title');
    }

    return Utils.isPresent(poiFieldErrors) ? poiFieldErrors : false;
};

export const buildPoiFormData = (form, parameters) => {
    const {
        plan,
        poiCoordinates
    } = parameters || {};

    const poiData = new FormData(form);

    if (plan) {
        poiData.append('plan', true);
        poiData.append('poi[status]', 'place');
    }

    if (poiCoordinates) {
        poiData.append('poi[latitude]', poiCoordinates.lat);
        poiData.append('poi[longitude]', poiCoordinates.lng);
    }

    const poiErrors = checkPoiErrors(poiData, plan);

    if (poiErrors) {
        return {
            errors: true,
            poiErrors
        };
    }

    return poiData;
};
