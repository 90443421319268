import {isPresent, parseUrlParams} from '@js/modules/utils';
import {paramsToURL} from '@js/modules/paramsToUrl';

const History = (function () {
    function _omitEmptyParams(params) {
        const newObject = {};
        Object.entries(params)
            .forEach(([key, value]) => {
                if (key !== '' && isPresent(key) && value !== '' && isPresent(value)) {
                    newObject[key] = value;
                }
            });
        return newObject;
    }

    function saveCurrentState(paramsToSerialize, paramsToUrl, {keepPreviousParams, replaceOnly} = {}) {
        if (window.history?.pushState) {
            paramsToSerialize = _omitEmptyParams(paramsToSerialize);

            let newPath = window.location.pathname ;
            const currentUrlParams = keepPreviousParams ? _omitEmptyParams(parseUrlParams()) : {};

            const newParams = _omitEmptyParams({...currentUrlParams, ...paramsToUrl});

            const urlPathParams = paramsToURL(newParams);

            if (isPresent(urlPathParams)) {
                newPath += '?' + urlPathParams;
            }

            if (replaceOnly) {
                try {
                    window.history.replaceState(paramsToSerialize, '', newPath);
                } catch (_) {
                    // Do nothing
                }
            } else {
                try {
                    window.history.pushState(paramsToSerialize, '', newPath);
                } catch (_) {
                    // Do nothing
                }
            }

            return true;
        } else {
            return false;
        }
    }

    function getPreviousState(dataName, options) {
        let params = {};
        if (window.history?.state) {
            params = window.history.state;
        }

        if (params[dataName]) {
            let dataParams = params[dataName];

            if (options?.useUrlParams) {
                const urlParams = parseUrlParams();
                dataParams = {...urlParams, ...dataParams};
            }

            return dataParams;
        } else if (options?.useUrlParams) {
            return parseUrlParams();
        } else {
            return false;
        }
    }

    function onStateChange(callback) {
        if (window.history) {
            window.onpopstate = function (event) {
                if (typeof callback === 'function') {
                    if (event.state) {
                        callback(event.state, document.location);
                    } else {
                        window.location = event.target.location;
                    }
                }

                return true;
            };
        }
    }

    return {
        saveCurrentState: saveCurrentState,
        getPreviousState: getPreviousState,
        onStateChange: onStateChange
    };
})();

export default History;
