import {addToBasket} from '@js/actions/basketActions';

import {basketStore} from '@js/stores/basketStore';

import $ from '@js/modules/selector';

import Notification from '@js/components/layouts/notification';

import {checkoutPath} from '@js/constants/routesHelper';

import AnalyticsService from '@js/modules/analyticsService';


class TrialManager {
    _isBannerVisible = false;

    _minNavbarScroll = 200;
    _lastScrollTop = 0;

    _bannerId = '#trial-banner';
    _basketButtonId = '#trial-add-basket-discover';
    _closeButtonId = '#trial-banner-close';

    _$scrollNavbar = null;
    _maxBannerShowAfterClose = 3;
    _closeBannerStorageKey = 'trial-banner-close-count';

    /* Public methods
    ******************** */
    initialize = () => {
        if (!window.features.trial?.active) {
            return;
        }

        this._isBannerVisible = true;

        this._manageBanner();
        this._handleCloseBanner();

        this._handleAddToBasketClick();
    };

    /* Private methods
    ******************** */
    _manageBanner = () => {
        if (window.sessionStorage) {
            let closeCount = window.sessionStorage.getItem(this._closeBannerStorageKey);
            closeCount = closeCount ? parseInt(closeCount) : 0;

            if (closeCount >= this._maxBannerShowAfterClose) {
                this._hideBanner();
                return;
            }
        }

        this._showBanner();

        // detect scroll top or down
        this._$scrollNavbar = document.getElementById('trial-banner');
        if (this._$scrollNavbar && this._isBannerVisible) {
            // Already defined in CSS
            // computePaddingTop();

            if (navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1) {
                // Remove annoying transform properties with modal
                this._$scrollNavbar.style['-webkit-transform'] = '';

                // Safari doesn't support scroll
                // return;
            }

            window.addEventListener('scroll', this._handleWindowScroll);
        }
    };

    _handleWindowScroll = () => {
        // var scrollTop = window.scrollTop;
        const scrollTop = ((document.compatMode || '') === 'CSS1Compat') ? document.documentElement.scrollTop : document.body.scrollTop;

        if (scrollTop < this._lastScrollTop || scrollTop < this._minNavbarScroll) {
            this._$scrollNavbar.classList.remove('scrolled-down');
            this._$scrollNavbar.classList.add('scrolled-up');
        } else {
            this._$scrollNavbar.classList.remove('scrolled-up');
            this._$scrollNavbar.classList.add('scrolled-down');
        }

        this._lastScrollTop = scrollTop;
    };

    _handleCloseBanner = () => {
        const $closeButton = $(this._closeButtonId);
        if (!$closeButton) {
            return;
        }

        $closeButton.on('click', (event) => {
            event.preventDefault();

            this._hideBanner();

            if (window.sessionStorage) {
                let closeCount = window.sessionStorage.getItem(this._closeBannerStorageKey);
                closeCount = closeCount ? (parseInt(closeCount) + 1) : 1;

                window.sessionStorage.setItem(this._closeBannerStorageKey, closeCount.toString());
            }
        });
    };

    _showBanner = () => {
        const $banner = $(this._bannerId);
        if (!$banner) {
            return;
        }

        this._isBannerVisible = true;

        $banner.removeClass('scroll-up');
        $banner.removeClass('scroll-down');
        $banner.removeClass('hidden');
        $banner.addClass('block');
    };

    _hideBanner = () => {
        const $banner = $(this._bannerId);
        if (!$banner) {
            return;
        }

        this._isBannerVisible = false;

        window.removeEventListener('scroll', this._handleWindowScroll);

        $banner.removeClass('scroll-up');
        $banner.removeClass('scroll-down');
        $banner.removeClass('block');
        $banner.addClass('hidden');
    };

    _handleAddToBasketClick = () => {
        const $addBasketButton = $(this._basketButtonId);
        if (!$addBasketButton) {
            return;
        }

        $addBasketButton.on('click', (event) => {
            event.preventDefault();

            this._disableBasketButton();

            const productId = $addBasketButton.data('product-id');

            this._addToBasket(productId);
        });
    };

    _addToBasket = (productId) => {
        addToBasket(productId, 'discover_offer')
            .then((response) => {
                if (response.errors) {
                    Notification.error(response.errors.join(', '));

                    this._enableBasketButtons();

                    return;
                }

                const basket = response.basket;
                const product = basket?.products?.first();

                basketStore.basket = basket;

                AnalyticsService.trackAddToBasket(product.slug, product.name, basket.totalAmount)
                    .then(() => {
                        window.location = checkoutPath();
                    });
            });
    };

    _enableBasketButtons = () => {
        const $addBasketButton = $(this._basketButtonId);
        const $addBasketLoader = $(this._basketButtonId + '-loader');

        if ($addBasketButton) {
            $addBasketButton.removeAttr('disabled');
        }

        if ($addBasketLoader) {
            $addBasketLoader.addClass('hidden');
        }
    };

    _disableBasketButton = () => {
        const $addBasketButton = $(this._basketButtonId);
        const $addBasketLoader = $(this._basketButtonId + '-loader');

        if ($addBasketButton) {
            $addBasketButton.attr('disabled', 'disabled');
        }

        if ($addBasketLoader) {
            $addBasketLoader.removeClass('hidden');
        }
    };
}

export default new TrialManager();
