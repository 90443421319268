import {
    notificationDuration
} from '@js/components/modules/constants';

const Notification = (function () {
    const self = {
        notificationElement: document.getElementById('notification-element'),
        notificationContent: document.getElementById('notification-content'),
        infoIcon: document.getElementById('notification-info'),
        successIcon: document.getElementById('notification-success'),
        warningIcon: document.getElementById('notification-warning'),
        errorIcon: document.getElementById('notification-error'),
        notificationLevels: ['info', 'success', 'warning', 'error'],
        notificationDuration: notificationDuration,
        notificationAnimationDuration: 300,
        notificationAnimationClass: 'duration-300'
    };

    const _checkInitialNotification = function () {
        const flashes = document.querySelectorAll('.flash-message');

        Array.prototype.forEach.call(flashes, function (element) {
            const level = element.getAttribute('data-level');
            const token = element.getAttribute('data-flash-token');

            if (sessionStorage) {
                // Do not display same flash message twice
                if (sessionStorage.getItem(`flash-message-${token}`)) {
                    return;
                }
            }

            // Let's the Notification component initialize
            document.addEventListener('DOMContentLoaded', function () {
                setTimeout(function () {
                    if (level === 'success') {
                        self.success(element.innerHTML);
                    } else if (level === 'error') {
                        self.error(element.innerHTML);
                    } else {
                        self.info(element.innerHTML);
                    }
                }, 200);
            });

            if (sessionStorage) {
                sessionStorage.setItem(`flash-message-${token}`, 'true');
            }
        });
    };

    const _adaptToLevel = function (level) {
        self.notificationLevels.forEach((l) => self.notificationElement.classList.remove(`notification-${l}`));

        self.notificationElement.classList.add(`notification-${level}`);
    };

    const _showIcon = function (level) {
        self.notificationLevels.forEach((l) => self[`${l}Icon`].classList.add('hidden'));

        self[`${level}Icon`].classList.remove('hidden');
    };

    const _setContent = function (content) {
        self.notificationContent.innerHTML = content;
    };

    const _hideNotification = function () {
        self.notificationElement.classList.add('transition-opacity', self.notificationAnimationClass, 'ease-out');
        self.notificationElement.classList.add('opacity-0');

        setTimeout(() => self.notificationElement.classList.remove('opacity-100'), 1);

        setTimeout(() => self.notificationElement.classList.add('hidden'), self.notificationAnimationDuration);
    };

    const _showNotification = function (autoHide = true) {
        self.notificationElement.classList.remove('hidden');
        self.notificationElement.classList.add('transition-opacity', self.notificationAnimationClass, 'ease-out');
        self.notificationElement.classList.add('opacity-0');

        setTimeout(() => self.notificationElement.classList.add('opacity-100'), 1);

        if (autoHide) {
            setTimeout(() => _hideNotification(), self.notificationDuration);
        }
    };

    /* Initialization
    ******************** */
    self.initialize = function () {
        _checkInitialNotification();
    };

    self.notificationLevels.forEach((level) => {
        self[level] = (content, autoHide = true) => {
            if (!self.notificationElement) {
                return;
            }

            _adaptToLevel(level);

            _showIcon(level);

            _setContent(content);

            _showNotification(autoHide);
        };
    });

    self.hide = function () {
        _hideNotification();
    };

    return self;
})();

export default Notification;
