<script>
    import Computation from '@js/modules/computation';

    import Icon from '@js/components/themes/Icon.svelte';
    import visibilityOffIcon from '@js/components/icons/visibilityOffIcon';
    import mapClockIcon from '@js/components/icons/mapClockIcon';
    import runIcon from '@js/components/icons/runIcon';
    import hikeIcon from '@js/components/icons/hikeIcon';
    import bicycleIcon from '@js/components/icons/bicycleIcon';
    import walkIcon from '@js/components/icons/walkIcon';

    const {
        ride
    } = $props();

    const RIDE_TYPE_ICON = {
        running: runIcon,
        hiking: hikeIcon,
        bike: bicycleIcon,
        walk: walkIcon
    };
</script>

<a class="relative group block my-4 mx-2 lg:px-2 bg-white"
   href={ride.link}>
    <img class="basis-28 w-full h-40 md:h-52 object-cover object-center rounded-lg shadow-md"
         srcset={ride.defaultPicture.medium ? ride.defaultPicture.medium.jpg : ride.defaultPicture.jpg}
         src={ride.defaultPicture.medium ? ride.defaultPicture.medium.jpg : ride.defaultPicture.jpg}
         alt={ride.name}/>

    <div class="relative px-2 -mt-6 md:-mt-8">
        <div class="bg-white p-4 rounded-lg shadow-lg transition duration-500 ease-in-out transform group-hover:-translate-y-3 group-hover:shadow-2xl">
            <div class="flex items-baseline justify-between h-6">
                <div class="flex items-center justify-start">
                    <div class="inline-block px-2 py-1 text-primary-900 text-xs font-semibold bg-primary-100 rounded-full tracking-wide">
                        <Icon class="inline-flex h-4 w-4 me-1"
                              icon={RIDE_TYPE_ICON[ride.rideType]}/>
                        {ride.rideTypeTranslated}
                    </div>

                    {#if ride.planned}
                        <Icon class="ml-3 text-primary-900 shrink-0 w-4 h-4"
                              icon={mapClockIcon}/>
                    {/if}

                    {#if ride.visibility === 'only_me'}
                        <Icon class="ml-3 text-primary-900 shrink-0 w-4 h-4"
                              icon={visibilityOffIcon}/>
                    {/if}
                </div>

                {#if ride.level !== 'level_undefined'}
                    <div class="ml-2 px-2 py-1 text-primary-900 text-xs font-semibold border-1 border-primary-900 rounded-full tracking-wider">
                        {ride.levelTranslated}
                    </div>
                {/if}
            </div>

            <h4 class="mt-3 md:h-12 text-lg font-semibold leading-tight overflow-hidden">
                {ride.name}
            </h4>

            <div class="mt-2 text-base font-semibold tracking-wider whitespace-nowrap overflow-hidden">
                {@html Computation.formatDistance(ride.distance, true, false, 2).replace('.', ',').replace(/(?!,)(\D+)/g, '<span class="text-sm text-gray-600 font-normal">$1</span>')}
                {#if !!ride.formattedDuration}
                    <span class="text-xs text-gray-500 font-normal">&nbsp;&bull;&nbsp;</span>
                    {@html ride.formattedDuration.replace(/(?!,)(\D+)/g, '<span class="text-sm text-gray-600 font-normal">$1</span>')}
                {/if}
                {#if (ride.rideType === 'running' || ride.rideType === 'bike' && !!ride.formattedSpeed)}
                    <span class="text-xs text-gray-500 font-normal">&nbsp;&bull;&nbsp;</span>
                    {@html Computation.formatSpeed(ride.formattedSpeed).replace('.', ',').replace(/(?!,)(\D+)/g, '<span class="text-sm text-gray-600 font-normal">$1</span>')}
                {/if}
                {#if (ride.rideType === 'hiking' && !!ride.positiveElevation)}
                    <span class="text-xs text-gray-500 font-normal">&nbsp;&bull;&nbsp;</span>
                    +{@html Computation.formatDistance(ride.positiveElevation, false, false, 2).replace('.', ',').replace(/(?!,)(\D+)/g, '<span class="text-sm text-gray-600 font-normal">$1</span>')}
                {/if}
            </div>

            <div class="mt-2">
                <div class="text-xs text-gray-500 truncate">
                    {ride.placesBreadcrumb.map((place) => place.title)
                        .slice(1, 3)
                        .join(' - ')}
                </div>
            </div>
        </div>
    </div>
</a>
