<script>
    import {onMount} from 'svelte';

    import {slide} from 'svelte/transition';
    import {cubicInOut} from 'svelte/easing';

    import classnames from 'classnames';

    import {userStore} from '@js/stores/userStore';
    import {mapStore} from '@js/stores/mapStore';

    import I18n from '@js/modules/translations';
    import * as Utils from '@js/modules/utils';

    import Notification from '@js/components/layouts/notification';

    import {MAP_CLASS, MAP_MODULES} from '@js/modules/maps/mapConstants';
    import MapBuilder from '@js/modules/maps/MapBuilder';
    import MapToolbar from '@js/components/maps/MapToolbar.svelte';
    import MapLayers from '@js/components/maps/MapLayers.svelte';
    import MapLoader from '@js/components/maps/MapLoader.svelte';
    import MapDistance from '@js/components/maps/MapDistance.svelte';
    import MapHelper from '@js/components/maps/MapHelper.svelte';
    import MapElevationButton from '@js/components/maps/MapElevationButton.svelte';
    import MapElevationGraph from '@js/components/maps/MapElevationGraph.svelte';

    import StorageService from '@js/modules/storage';
    import AnalyticsService from '@js/modules/analyticsService';

    const {
        mapId,
        class: className,
        rideId,
        reusePreviousTracks = true,
        initialMarkerPosition,
        initialMapPoints,
        onDistanceChange
    } = $props();

    const TIME_STORAGE_KEY = 'MapTimeStore';

    let _previousMapDistance = 0;
    let _previousMapLocationValue;
    let _previousUnit = userStore.unit;

    let isShowElevation = $state(false);
    let serializedMapData = $state('');

    const _initialMapPoints = () => {
        if (initialMapPoints) {
            if (Utils.is()
                .isString(initialMapPoints)) {
                const stringPoints = initialMapPoints.split(';');
                return stringPoints.map((stringPoint) => {
                    const point = stringPoint.split(',');

                    if (point.length > 2) {
                        return {
                            type: point[0],
                            latitude: parseFloat(point[1]),
                            longitude: parseFloat(point[2]),
                            delta_distance: point[4] && parseFloat(point[3]),
                            delta_time: point[4] && parseFloat(point[4]),
                            delta_pause: point[5] && parseFloat(point[5])
                        };
                    } else {
                        return null;
                    }
                })
                    .compact();
            } else {
                return initialMapPoints;
            }
        } else {
            return null;
        }
    };

    const _serializeMap = (options = {}) => {
        if (!mapStore.map) {
            return;
        }

        if (mapStore.map.hasOnlyOnePoint()) {
            isShowElevation = false;

            Notification.error(I18n.t('js.maps.errors.one_point.text'));
            return false;
        }

        // check for a map, serialize the points
        const hasActivityData = mapStore.map.hasActivityData();
        if (hasActivityData) {
            const serializedPoints = mapStore.map.serializePoints();

            return {
                serializedPoints: serializedPoints,
                staticMap: options.staticMap ? mapStore.map.staticMapUrl() : undefined
            };
        } else {
            return false;
        }
    };

    const _loadHomeMap = () => {
        MapBuilder.createMap(
            MAP_CLASS.BUILD,
            [MAP_MODULES.TOOLBAR, MAP_MODULES.RIDE_STORE, MAP_MODULES.RIDE_POIS, MAP_MODULES.GRAPH, MAP_MODULES.HELPER, MAP_MODULES.GEOLOCATION],
            {
                mapId,
                rideId,
                reusePreviousTracks,
                initialPoints: _initialMapPoints(),
                initialLocation: initialMarkerPosition || mapStore.mapLocation,
                reusePosition: !initialMarkerPosition && !mapStore.mapLocation,
                useSearchMarker: !!initialMarkerPosition,
                defaultZoom: 12
            }
        )
            .then((map) => {
                if (!map) {
                    return;
                }

                mapStore.map = map;

                mapStore.map.onDistanceUpdated((newDistanceInKm) => {
                    if (mapId === 'home-map' && !_previousMapDistance && !!newDistanceInKm) {
                        AnalyticsService.trackHomePathStarted();
                    }

                    const formattedDistance = Math.round(newDistanceInKm * 1000);

                    if (mapStore.currentDistance === formattedDistance) {
                        return;
                    }

                    if (onDistanceChange) {
                        onDistanceChange(formattedDistance);
                    } else {
                        mapStore.currentDistance = formattedDistance;

                        if (mapStore.currentDistance) {
                            if (mapStore.currentDistance && _previousMapDistance !== mapStore.currentDistance) {
                                const deltaDistance = Math.floor(Math.abs(mapStore.currentDistance - (_previousMapDistance || 0)));

                                if (deltaDistance > 0) {
                                    AnalyticsService.trackMapDistanceComputed(deltaDistance);
                                }

                                _previousMapDistance = mapStore.currentDistance;
                            }
                        }
                    }

                    if (isShowElevation) {
                        const serializedData = _serializeMap();
                        if (serializedData) {
                            serializedMapData = serializedData.serializedPoints;
                        }
                    }
                });

                userStore.subscribe((userState) => {
                    if (userState.unit !== _previousUnit) {
                        _previousUnit = userState.unit;

                        mapStore.map.onUnitChanged(userState.unit);
                    }
                });
            });
    };

    const _handleSnapToRoadChange = (checked) => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.setSnapToRoad(checked);
    };

    const _handleRouteTypeChange = (routeType) => {
        if (!mapStore.map) {
            return;
        }

        if (routeType === 'hike') {
            mapStore.currentRouteType = 'hiking';
        } else if (routeType === 'bike' || routeType === 'mtb') {
            mapStore.currentRouteType = 'bike';
        } else {
            mapStore.currentRouteType = 'running';
        }

        mapStore.map.setRouteType(routeType);
    };

    const _handleBackToHomeClick = () => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.backToHome();
    };

    const _handleUndoClick = () => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.undo();
    };

    const _handleClearClick = () => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.clearMap(() => {
            isShowElevation = false;
            serializedMapData = '';

            StorageService.removeItem(TIME_STORAGE_KEY);
        }, {
            noPopup: userStore.isConnected
        });
    };

    const _handleMapStyleChange = (mapStyle) => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.setMapStyle(mapStyle);
    };

    const _handleElevationClick = () => {
        if (!mapStore.map) {
            return;
        }

        if (window.isMetricsEnabled && !isShowElevation) {
            AnalyticsService.trackComputeElevationClick('Home page');
        }

        isShowElevation = !isShowElevation;

        const serializedData = _serializeMap();
        if (serializedData) {
            serializedMapData = serializedData.serializedPoints;
        }
    };

    const _handleElevationComputed = (elevationPoints) => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.updatePointsWithElevation(elevationPoints);
    };

    const _setMapLocation = (bbox, center, placeType) => {
        if (!mapStore.map) {
            return;
        }

        mapStore.map.goToLocation(bbox, center, {
            showPositionMarker: true,
            startMarker: ['address', 'street'].includes(placeType)
        });
    };

    // PATCHME: add geolocation
    // _handleGeolocateClick = (event) => {
    //     event.preventDefault();
    //
    //     AnalyticsService.trackGeolocationClick('Home page');
    //
    //     if (mapStore.map) {
    //         mapStore.map.onGeolocation();
    //
    //         this.setState({
    //             isGeolocating: true
    //         });
    //     }
    // };

    onMount(() => {
        if (!$mapStore.map) {
            _loadHomeMap();
        }

        return () => {
            if ($mapStore.map) {
                mapStore.map.destroy();
                mapStore.map = null;
            }
        };
    });

    $effect(() => {
        if ($mapStore.mapLocation && ($mapStore.mapLocation.bbox || $mapStore.mapLocation.center) && _previousMapLocationValue !== $mapStore.mapLocation.value) {
            _previousMapLocationValue = $mapStore.mapLocation.value;

            _setMapLocation($mapStore.mapLocation.bbox, $mapStore.mapLocation.center, $mapStore.mapLocation.placeType);
        }
    });
</script>

<div id={mapId}
     class="my-4">
    <div class="map-area">
        <div id={`${mapId}-map`}
             class={classnames('map-canvas relative', className)}>
            <MapToolbar isSnapToRoadChecked={$userStore.featureFlags.map_snap_to_road_enabled}
                        isRouteTypeAvailable={$userStore.featureFlags.map_route_type_enabled}
                        isPoiModeChecked={false}
                        onSnapToRoadChange={_handleSnapToRoadChange}
                        onRouteTypeChange={_handleRouteTypeChange}
                        onBackToHomeClick={_handleBackToHomeClick}
                        onUndoClick={_handleUndoClick}
                        onClearClick={_handleClearClick}/>

            <MapDistance/>

            {#if $userStore.featureFlags.map_available_layers?.length}
                <MapLayers mapLayersAvailable={$userStore.featureFlags.map_available_layers}
                           onMapStyleChange={_handleMapStyleChange}/>
            {/if}

            <MapHelper/>
        </div>

        <MapLoader/>
    </div>

    {#if isShowElevation}
        <div transition:slide={{duration: 300, easing: cubicInOut}}>
            <MapElevationGraph map={$mapStore.map}
                               currentRouteType={$mapStore.currentRouteType}
                               serializedMapData={serializedMapData}
                               warningHelper={true}
                               onElevationComputed={_handleElevationComputed}/>
        </div>
    {/if}

    <MapElevationButton onElevationToggle={_handleElevationClick}/>
</div>
