<script>
    import classnames from 'classnames';

    import Icon from '@js/components/themes/Icon.svelte';

    let {
        class: className,
        items,
        openedTabId = $bindable(),
        onTabChange
    } = $props();

    const _handleItemClick = (event, itemId) => {
        event.preventDefault();

        openedTabId = itemId;

        onTabChange(itemId);
    };
</script>

<div class={classnames('mx-auto text-center border-b border-gray-200 dark:border-gray-700', className)}>
    <ul class="flex items-center justify-around flex-wrap -mb-px text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        {#each items as item (item.id)}
            <li class="me-2">
                <button class={classnames('group inline-flex items-center justify-center p-3 rounded-t-lg', openedTabId === item.id && 'active text-primary-600 border-b-2 border-primary-600 dark:text-primary-500 dark:border-primary-500', openedTabId !== item.id && 'border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300')}
                        onclick={(event) => _handleItemClick(event, item.id)}>
                    <Icon class="inline-flex h-5 w-5 me-2"
                          icon={item.icon}/>
                    {item.title}
                </button>
            </li>
        {/each}
    </ul>
</div>
