<script>
    import I18n from '@js/modules/translations';

    import Checkbox from '@js/components/themes/Checkbox.svelte';
    // import Input from '@/components/themes/Input.svelte';
    import PlanFormDate from '@js/components/plan/form/PlanFormDate.svelte';
    import Input from '@js/components/themes/Input.svelte';

    const {
        namePlanned,
        namePerformedAt,
        labelPlanned = I18n.t('js.ride.model.planned'),
        labelPerformedAt = I18n.t('js.ride.model.performed_at'),
        defaultPlanned = false,
        defaultPlanDateType,
        defaultPlannedAt,
        defaultPerformedAt,
        onPlannedChanged
    } = $props();

    let planned = $state(defaultPlanned);

    const _handlePlannedChange = (event) => {
        event.preventDefault();

        planned = event.target.checked;

        if (onPlannedChanged) {
            onPlannedChanged(planned);
        }
    };
</script>

<Input type="date"
       class="mx-auto my-4 w-full"
       inputClass="py-2"
       name={namePerformedAt}
       label={labelPerformedAt}
       defaultValue={defaultPerformedAt}
       onfocus={(event) => event.target.showPicker()}>
</Input>

<Checkbox name={namePlanned}
          color="primary"
          checked={planned}
          onchange={_handlePlannedChange}>
    {labelPlanned}
</Checkbox>

{#if planned}
    <!--    <Input type="date"-->
    <!--           class="mx-auto my-4 w-full"-->
    <!--           inputClass="py-2"-->
    <!--           name={namePlannedAt}-->
    <!--           label={labelPlannedAt}-->
    <!--           value={defaultPlannedAt}-->
    <!--           onfocus={(event) => event.target.showPicker()}>-->
    <!--    </Input>-->

    <PlanFormDate class="mx-auto my-4 w-full"
                  nameDateType="ride[plan_date_type]"
                  defaultDateType={defaultPlanDateType}
                  namePlannedAt="ride[planned_at]"
                  defaultPlannedAt={defaultPlannedAt}
                  isCompletedExclude={true}/>
{/if}
