<script>
    import I18n from '@js/modules/translations';

    import Dropdown from '@js/components/themes/Dropdown.svelte';

    import {RideLevels} from '@js/components/modules/constants';

    const {
        name,
        label = I18n.t('js.ride.model.level'),
        defaultValue = 'level_undefined',
        onLevelChange
    } = $props();

    let rideLevel = $state(defaultValue);

    const _handleLevelChange = (level, event) => {
        event.preventDefault();

        rideLevel = level;

        if (onLevelChange) {
            onLevelChange(level);
        }
    };
</script>

<Dropdown label={label}
          class="mx-auto w-full"
          buttonClass="w-full px-4 py-2 flex justify-between rounded-lg text-sm"
          color="gray"
          name={name}
          selected={rideLevel}
          optionSize={RideLevels.length}
          closeClickInside={true}>
    {#snippet buttonRender()}
    <span class="flex items-center justify-center">
        {I18n.t(`js.ride.enums.level.${rideLevel}`)}
    </span>
    {/snippet}

    <ul class="text-sm text-gray-700 dark:text-gray-200"
        aria-labelledby="RouteLevel">
        {#each RideLevels as level}
            <li class="flex items-center justify-start p-2 cursor-pointer hover:bg-gray-300"
                class:bg-primary-200={rideLevel === level}
                onclick={_handleLevelChange.bind(null, level)}>
                {I18n.t(`js.ride.enums.level.${level}`)}
            </li>
        {/each}
    </ul>
</Dropdown>
