<script>
    import I18n from '@js/modules/translations';

    import {mapStore} from '@js/stores/mapStore';

    const {
        hasElevationData = false,
        onElevationToggle
    } = $props();

    let isShowElevation = $state(false);

    const _handleElevationClick = (event) => {
        event.preventDefault();

        isShowElevation = !isShowElevation;

        onElevationToggle();
    };
</script>

{#if hasElevationData || $mapStore.currentDistance}
    <div id="map-elevation"
         class="map-elevation-container">
        <button class="map-elevation-button"
                onclick={_handleElevationClick}>
            {I18n.t('js.ride.common.elevation')}

            <svg class="w-2.5 h-2.5 mt-0.5 ms-1.5"
                 class:rotate-180={isShowElevation}
                 aria-hidden="true"
                 xmlns="http://www.w3.org/2000/svg"
                 fill="none"
                 viewBox="0 0 10 6">
                <path stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="1.5"
                      d="m1 1 4 4 4-4"/>
        </button>
    </div>
{/if}
