
import I18n from '@js/modules/translations';

class Computation {
    UNIT_CONVERSION = {
        KM_PER_MILE: 1.609344,
        M_PER_FEET: 0.3048,
        PI: 3.1415926,
        DEG_TO_RAD: 0.0174532925199433,
        EARTH_RADIUS: 6370.997
    };

    /* Public Methods
     ******************** */
    formatDistance = (distance, smartUnit = false, k_unit = true, decimalLimit = 3, unit = window.unit) => {
        if (!distance) {
            return `0${I18n.t(`js.metrics.${unit}.distance_unit`)}`;
        }

        if (smartUnit) {
            if (this.meterToUnit(distance) > (unit === 'mi' ? this.UNIT_CONVERSION.KM_PER_MILE * 1000 : 1000)) {
                return `${this.kmToUnit(distance / (unit === 'mi' && !k_unit ? 1 : 1_000), decimalLimit)}${I18n.t(`js.metrics.${unit}.distance_unit_k`)}`;
            } else {
                return `${this.meterToUnit(distance, 0)}${I18n.t(`js.metrics.${unit}.distance_unit`)}`;
            }
        } else if (!k_unit) {
            return `${this.meterToUnit(distance, decimalLimit)}${I18n.t(`js.metrics.${unit}.distance_unit`)}`;
        } else {
            return `${this.kmToUnit(distance, decimalLimit)}${I18n.t(`js.metrics.${unit}.distance_unit_k`)}`;
        }
    };

    formatMeterDistance = (distance, unit = window.unit) => {
        return `${this.meterToUnit(distance)}${I18n.t(`js.metrics.${unit}.distance_unit`)}`;
    };

    formatPace = (pace, unit = window.unit) => {
        return `${this.meterToUnit(pace)}${I18n.t(`js.metrics.${unit}.distance_unit`)}/h`;
    };

    formatDuration = (time, withSeconds = true, formatted = true, withLeadingZero = true) => {
        if (!time) {
            return undefined;
        } else if (typeof time === 'string' && time.includes(':')) {
            const [hours, minutes, seconds] = time.split(':');
            if (formatted) {
                return this._displayFormattedTime(Math.floor(parseInt(hours, 10)), Math.floor(parseInt(minutes, 10)), withSeconds ? Math.floor(parseInt(seconds, 10)) : undefined, withLeadingZero);
            } else {
                const durationInSeconds = (parseInt(hours) || 0) * 3600 + (parseInt(minutes) || 0) * 60 + (parseInt(seconds) || 0);
                return durationInSeconds;
            }
        } else {
            const [hours, minutes, seconds] = this._secondsToHMS(time);

            return this._displayFormattedTime(hours, minutes, withSeconds ? seconds : undefined, withLeadingZero);
        }
    };

    isDurationEqual = (timeA, timeB) => {
        if (!timeA || !timeB) {
            return false;
        }

        let formattedTimeA;
        if (typeof timeA === 'string') {
            if (timeA.includes(':')) {
                const [hoursA, minutesA, secondsA] = timeA.split(':');
                formattedTimeA = parseInt(hoursA, 10) + parseInt(minutesA, 10) + parseInt(secondsA, 10);
            } else {
                formattedTimeA = parseInt(timeA, 10);
            }
        } else {
            formattedTimeA = timeA;
        }

        let formattedTimeB;
        if (typeof timeB === 'string' && timeB.includes(':')) {
            if (timeB.includes(':')) {
                const [hoursB, minutesB, secondsB] = timeB.split(':');
                formattedTimeB = parseInt(hoursB, 10) * 3600 + parseInt(minutesB, 10) * 60 + parseInt(secondsB, 10);
            } else {
                formattedTimeB = parseInt(timeB, 10);
            }
        } else {
            formattedTimeB = timeB;
        }

        return formattedTimeA === formattedTimeB;
    };

    formatSpeed = (speed, unitTarget, unit = window.unit) => {
        return speed ? `${this.speedToUnit(speed, unitTarget, unit)}${I18n.t(`js.metrics.${unit}.speed_unit`)}` : undefined;
    };

    computeDuration = (meterDistance, speed, chronoDuration = false, formatted = true, unit = window.unit) => {
        if (unit === 'mi') {
            meterDistance /= this.UNIT_CONVERSION.KM_PER_MILE;
        }

        const durationInSeconds = (parseFloat(meterDistance) / parseFloat(speed)) * 3.6;

        if (formatted) {
            const [hours, minutes, seconds] = this._secondsToHMS(durationInSeconds);

            return chronoDuration ? this._formatToChrono(hours, minutes, seconds) : this._displayFormattedTime(hours, minutes, seconds);
        } else {
            return durationInSeconds;
        }
    };

    computeSpeed = (distance, duration, formatted = true) => {
        if (!distance || !duration) {
            return 0;
        }

        let durationInSeconds;
        if (typeof duration === 'string' && duration.includes(':')) {
            const durations = duration.split(':');

            durationInSeconds = (parseInt(durations[2]) || 0) + (parseInt(durations[1]) || 0) * 60 + (parseInt(durations[0]) || 0) * 3600;
        } else {
            durationInSeconds = duration;
        }

        const speed = durationInSeconds ? (parseFloat(distance) / 1000) / (durationInSeconds / 3600) : 0;

        return formatted ? this.formatSpeed(speed) : speed;
    };

    kmToUnit = (distance, precision = 0, unit = window.unit) => {
        if (typeof distance === 'string') {
            distance = parseFloat(distance);
        }

        if (unit === 'mi') {
            distance /= this.UNIT_CONVERSION.KM_PER_MILE * 1000;
            return distance > 10 ? Math.round(distance) : distance.toFixed(precision || 3);
        } else {
            return precision ? distance.toFixed(precision) : Math.round(distance * 1000);
        }
    };

    meterToUnit = (distance, precision = 0, unit = window.unit) => {
        if (typeof distance === 'string') {
            distance = parseFloat(distance);
        }

        if (unit === 'mi') {
            distance /= this.UNIT_CONVERSION.M_PER_FEET;
            return distance > 10 ? Math.round(distance) : parseFloat(distance.toFixed(precision || 3));
        } else {
            return precision ? parseFloat(distance.toFixed(precision)) : Math.round(distance);
        }
    };

    speedToUnit = (speed, unitTarget, unit = window.unit) => {
        if (typeof speed === 'string') {
            speed = parseFloat(speed);
        }

        if (unitTarget === 'kmh') {
            speed *= this.UNIT_CONVERSION.KM_PER_MILE;
        } else if (unitTarget === 'mph') {
            // Do nothing
        } else if (unit === 'mi') {
            speed /= this.UNIT_CONVERSION.KM_PER_MILE;
        }

        return parseFloat(speed.toFixed(2));
    };

    combineChronoDuration = (firstChrono, secondChrono) => {
        if (!firstChrono || !secondChrono) {
            return firstChrono || secondChrono;
        }

        const [firstHours, firstMinutes, firstSeconds] = firstChrono.split(':')
            .map((time) => parseInt(time));
        const [secondHours, secondMinutes, secondSeconds] = secondChrono.split(':')
            .map((time) => parseInt(time));

        let newHours = firstHours + secondHours;
        let newMinutes = firstMinutes + secondMinutes;
        let newSeconds = firstSeconds + secondSeconds;

        if (newSeconds > 59) {
            newMinutes += 1;
            newSeconds %= 60;
        }
        if (newMinutes > 59) {
            newHours += 1;
            newMinutes %= 60;
        }

        return this._formatToChrono(newHours, newMinutes, newSeconds);
    };

    formatHeartRate = (heartRate) => {
        return `${heartRate}${I18n.t('js.metrics.heart_rate')}`;
    };

    /* Private Methods
    ******************** */
    _secondsToHMS = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds - (hours * 3600)) / 60);
        const seconds = Math.floor(durationInSeconds - (hours * 3600) - (minutes * 60));

        return [hours, minutes, seconds];
    };

    _displayFormattedTime = (hours, minutes, seconds, withLeadingZero = false) => {
        if (hours && hours < 10) {
            hours = withLeadingZero ? '0' + hours : hours;
        } else if (!hours) {
            hours = 0;
        }
        if (minutes && minutes < 10) {
            minutes = withLeadingZero ? '0' + minutes : minutes;
        } else if (!minutes) {
            minutes = 0;
        }
        if (seconds && seconds < 10) {
            seconds = withLeadingZero ? '0' + seconds : seconds;
        } else if (!seconds) {
            seconds = 0;
        }

        if (hours) {
            if (seconds) {
                return `${hours}h${minutes}m${seconds}s`;
            } else if (minutes) {
                return `${hours}h${minutes}`;
            } else {
                return `${hours}h`;
            }
        } else if (minutes) {
            if (seconds) {
                return `${minutes}min${seconds}sec`;
            } else {
                return `${minutes}min`;
            }
        } else {
            return `${seconds}sec`;
        }
    };

    _formatToChrono = (hours, minutes, seconds) => {
        return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };
}

export default new Computation();
