import api from '@js/actions/api';

import {userStore} from '@js/stores/userStore';

export const initUser = (userId) => (
    api
        .get(`/api/v1/users/${userId}`, {
            connection: true
        })
        .then((response) => {
            const currentUser = response?.user;

            if (currentUser) {
                userStore.user = currentUser;
                userStore.isConnected = true;
                userStore.featureFlags = currentUser.featureFlags;

                window.currentUserId = currentUser.id;
                window.currentUserSlug = currentUser.slug;
                window.currentUserRole = currentUser.role;

                if (response.meta?.token) {
                    const csrfToken = document.getElementsByName('csrf-token')[0];
                    csrfToken?.setAttribute('content', response.meta.token);
                }

                return currentUser;
            }
        })
);

export const showSignupModal = () => {
    userStore.state.signupModal?.show();
};

export const hideSignupModal = () => {
    userStore.state.signupModal?.hide();
};

export const showLoginModal = () => {
    userStore.state.loginModal?.show();
};

export const hideLoginModal = () => {
    userStore.state.loginModal?.hide();
};

export const validateUser = (validationParam, requestOptions = {}) => (
    api
        .get('/api/v1/users/validation', {
            user: validationParam
        }, requestOptions)
);

export const signupUser = (user, options = {}) => (
    api.post('/signup', {
        user,
        ...options
    })
);

export const loginUser = (user, options = {}) => (
    api.post('/login', {
        user,
        ...options
    })
);

export const updateUser = (userId, parameters, options = {}) => (
    api.update(`/api/v1/users/${userId}`, {
        user: parameters,
        ...options
    })
);

export const updateUserUnit = (newUnit, options = {}) => (
    api.update('/api/v1/users/unit', {
        forceUnit: newUnit,
        ...options
    })
);

