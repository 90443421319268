import {pushError} from '@js/actions/errorManagement';

import Notification from '@js/components/layouts/notification';

function convertRelationships(object, relationships, included) {
    if (relationships && included) {
        Object.entries(relationships)
            .forEach(([relationName, relationData]) => {
                if (!relationData.data) {
                    return;
                }

                if (Array.isArray(relationData.data)) {
                    object[relationName] = relationData.data.map((datum) => {
                        const relation = included.find((include) => include.id === datum.id && include.type === datum.type);

                        if (relation) {
                            return relation.attributes;
                        } else {
                            pushError({
                                statusText: `${JSON.stringify(relationData.data)} relation not found for ${JSON.stringify(object)}`
                            });

                            return undefined;
                        }
                    });
                } else {
                    const relation = included.find((include) => include.id === relationData.data.id && include.type === relationData.data.type);
                    if (relation) {
                        object[relationName] = relation.attributes;
                    } else {
                        pushError({
                            statusText: `${JSON.stringify(relationData.data)} relation not found for ${JSON.stringify(object)}`
                        });
                    }
                }
            });
    }

    return object;
}

const _handleNotificationDisplay = (notification) => {
    setTimeout(() => {
        Object.entries(notification)
            .forEach(([level, message]) => {
                const notificationMethod = Notification[level];
                if (notificationMethod) {
                    notificationMethod(message);
                } else {
                    Notification.info(message);
                }
            });
    }, 100);

    return notification;
};

export function handleJsonResponse(response) {
    let formattedResponse = {};

    if (response?.data?.type) {
        const rootKey = (response.meta && response.meta.root) || response.data.type;

        formattedResponse[rootKey] = {
            ...response.data.attributes
        };

        convertRelationships(formattedResponse[rootKey], response.data.relationships, response.included);
    } else if (response?.data && Array.isArray(response.data)) {
        if (response.meta?.root) {
            formattedResponse[response.meta.root] = response.data.map((datum) => convertRelationships({...datum.attributes}, datum.relationships, response.included));
        } else {
            formattedResponse = response.data.map((datum) => convertRelationships({...datum.attributes}, datum.relationships, response.included));
        }
    } else if (response.notification) {
        const {
            notification,
            ...otherResponse
        } = response;

        if (notification) {
            _handleNotificationDisplay(notification);
        }

        formattedResponse = otherResponse;
    } else {
        formattedResponse = response;
    }

    if (response?.meta) {
        const {
            root,
            ...meta
        } = response.meta;

        const {
            notification,
            ...otherMeta
        } = meta;

        if (notification) {
            _handleNotificationDisplay(notification);
        }

        formattedResponse.meta = otherMeta;
    }

    return formattedResponse;
}
