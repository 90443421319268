import {updateTrackingConfiguration} from '@js/actions/trackingActions';


class TrackingManager {
    _initialTrackingDelay = 250;

    /* Public methods
    ******************** */
    initialize = (forceRequest) => {
        setTimeout(() => {
            // Configuration request is used to confirm the user visit
            if (!this._alreadySent() || forceRequest) {
                var properties = {
                    valid: true
                };

                updateTrackingConfiguration(properties)
                    .then(() => {
                        if (!this._rejectCookies()) {
                            document.cookie = 'configurationSet=true;path=/';
                        }
                    })
                    .catch(() => {
                        if (!this._rejectCookies()) {
                            document.cookie = '';
                        }
                    });

                if (!this._rejectCookies()) {
                    document.cookie = 'configurationSet=true;path=/';
                }
            }
        }, this._initialTrackingDelay);
    };

    /* Private methods
    ******************** */
    _rejectCookies = () => {
        if (navigator.cookieEnabled) return false;
        if (!document.cookie.indexOf) return false;

        // set and read cookie
        document.cookie = 'cookietest=1';
        var ret = document.cookie.indexOf('cookietest=') === -1;

        // delete cookie
        document.cookie = 'cookietest=1; expires=Thu, 01-Jan-1970 00:00:01 GMT';

        return ret;
    };

    _alreadySent = () => {
        return this._rejectCookies() ? false : document.cookie.indexOf('configurationSet=') !== -1;
    };
}

export default new TrackingManager();
