import api from '@js/actions/api';

export const fetchRides = (filter = {}, options = {}, requestOptions = {}) => (
    api
        .get('/api/v1/rides', {
            filter,
            ...options
        }, requestOptions)
);

export const fetchHeartData = (rideId, requestOptions = {}) => (
    api
        .get(`/api/v1/performances/${rideId}/heart-data`, requestOptions)
);

export const fetchRideCharts = (rideId, requestOptions = {}) => (
    api
        .get(`/api/v1/rides/${rideId}/chart`, requestOptions)
);

export const fetchRideTracks = (rideId, requestOptions = {}) => (
    api
        .get(`/api/v1/rides/${rideId}/track`, requestOptions)
);

export const addRide = (rideData, isData = true) => (
    api
        .post('/api/v1/rides', rideData, isData)
);

export const updateRide = (rideId, rideData, isData = true) => (
    api
        .update(`/api/v1/rides/${rideId}`, rideData, isData)
);

export const checkJobProgress = (rideId, callback) => {
    api.get(`/api/v1/rides/${rideId}/processing`, {
        force_locale: window.locale,
        ride: {
            id: rideId
        }
    })
        .then((response) => {
            if (response) {
                let processingMessage = response.message;
                let currentStep = 1;
                let totalSteps = 4;
                let rideLink;

                if (response.currentStep) {
                    currentStep = parseInt(response.currentStep);
                }
                if (response.totalSteps) {
                    totalSteps = parseInt(response.totalSteps);
                }

                if (response.error) {
                    totalSteps = 0;
                } else if (response.complete) {
                    if (response.rideLink) {
                        rideLink = response.rideLink;
                    }
                } else {
                    processingMessage = `${processingMessage} (${currentStep}/${totalSteps})`;
                }

                callback({
                    rideId,
                    processingMessage,
                    currentStep,
                    totalSteps,
                    rideLink
                });
            } else {
                callback();
            }
        });
};

export const deleteRide = (rideId, options = {}) => (
    api
        .delete(`/api/v1/rides/${rideId}`, options)
);