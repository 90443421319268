import flowbiteInitializer from '@js/components/layouts/flowbite';
import * as Utils from '@js/modules/utils';
import {manageDrawer} from '@js/modules/drawer';

import TrackingManager from '@js/managers/trackingManager';
import UserManager from '@js/managers/userManager';
import BasketManager from '@js/managers/basketManager';
import TrialManager from '@js/managers/trialManager';

import Notification from '@js/components/layouts/notification';
import {initializeConnection} from '@js/components/layouts/connection';

import RouteManager from '@js/modules/routeManager';
import AnalyticsService from '@js/modules/analyticsService';
import PWAManager from '@js/modules/pwaManager';

if (process.env.NODE_ENV === 'production') {
    require('@js/production');
} else {
    require('@js/development');
}

// Define settings and features
window.settings = JSON.parse(document.getElementById('settings')?.innerText || '{}');
window.features = JSON.parse(document.getElementById('features')?.innerText || '{}');

// Check user and admin state
window.currentUserId ||= Utils.getCookie('user.connected_id');
window.currentAdminId ||= Utils.getCookie('admin.connected_id');

export default function InitializeLayout () {
    if (window.currentAdminId) {
        import('@js/managers/adminManager')
            .then(({default: AdminManager}) => {
                AdminManager.initialize();
            });
    }

    // Clean timestamp after connection
    Utils.clearForceReloadParam();

    // initialize components based on data attribute selectors
    flowbiteInitializer();

    manageDrawer('drawer-navigation', 'drawer-navigation-button', 'drawer-navigation-close');

    // Initialize notifications
    Notification.initialize();

    // Initialize connection modules
    initializeConnection();

    // Initialize routes
    RouteManager.initialize();

    // Tracking state management
    TrackingManager.initialize();

    // User management
    UserManager.initialize();

    // Basket management
    BasketManager.initialize();

    // Analytics
    AnalyticsService.initialize();

    // PWA manager
    PWAManager.initialize();

    // Trial
    TrialManager.initialize();
}
