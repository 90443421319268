<script>
    import classnames from 'classnames';

    const {
        id,
        class: className,
        // inputClass,
        label,
        name,
        placeholder,
        rows = '4',
        defaultValue,
        required,
        textHelper,
        error,
        // slots
        textHelperRender
    } = $props();
</script>

<label class={classnames('block space-y-2 text-sm rtl:text-right font-medium text-gray-900', className)}
       class:text-red-700={error}>
    <span>{label}</span>

    <textarea id={id}
              name={name}
              required={required}
              rows={rows}
              class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              placeholder={placeholder}
              value={defaultValue}></textarea>

    {#if textHelperRender}
        <p class="text-xs font-normal mt-2">
            {@render textHelperRender()}
        </p>
    {:else}
        <p class="text-xs font-normal mt-2"
           class:text-red-700={error}>
            {textHelper}
        </p>
    {/if}
</label>
