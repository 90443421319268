<script>
    import I18n from '@js/modules/translations';

    import Input from '@js/components/themes/Input.svelte';
    import Dropdown from '@js/components/themes/Dropdown.svelte';
    import Icon from '@js/components/themes/Icon.svelte';
    import dateIcon from '@js/components/icons/dateIcon';

    import {PlanDateTypes} from '@js/components/modules/constants';

    const {
        class: className,
        nameDateType,
        namePlannedAt,
        namePerformedAt,
        labelDateType = I18n.t('js.ride.plan.model.plan_date_type'),
        labelPlannedAt = I18n.t('js.ride.plan.model.planned_at'),
        labelPerformedAt = I18n.t('js.ride.model.performed_at'),
        defaultDateType = 'unplanned',
        defaultPlannedAt,
        defaultPerformedAt,
        onDateTypeChange,
        isCompletedExclude = false
        // onPlannedAtChange
    } = $props();

    let planDateType = $state(defaultDateType);

    const _handlePlanDateTypeChange = (dateType, event) => {
        event.preventDefault();

        planDateType = dateType;

        if (onDateTypeChange) {
            onDateTypeChange(dateType);
        }
    };

    let planDateTypes = $state(PlanDateTypes);
    if (isCompletedExclude) {
        planDateTypes = PlanDateTypes.slice(0, -1);
    }
</script>

<div class={className}>
    <Dropdown class="mx-auto w-full"
              buttonClass="w-full px-4 py-2 flex justify-between rounded-lg text-sm"
              color="gray"
              name={nameDateType}
              label={labelDateType}
              selected={planDateType}
              optionSize={planDateTypes.length}
              closeClickInside={true}>
        {#snippet buttonRender()}
        <span class="flex items-center justify-center">
            <Icon class="inline-flex h-4 w-4 me-2"
                  icon={dateIcon}/>
            {I18n.t(`js.ride.plan.enums.plan_date_type.${planDateType}`)}
        </span>
        {/snippet}

        <ul class="text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="DateType">
            {#each planDateTypes as dateType}
                <li class="flex items-center justify-start p-2 cursor-pointer hover:bg-gray-300"
                    class:bg-primary-200={planDateType === dateType}
                    onclick={_handlePlanDateTypeChange.bind(null, dateType)}>
                    {I18n.t(`js.ride.plan.enums.plan_date_type.${dateType}`)}
                </li>
            {/each}
        </ul>
    </Dropdown>

    {#if planDateType === 'date'}
        <Input type="date"
               class="mx-auto my-2 w-full"
               inputClass="py-2"
               name={namePlannedAt}
               label={labelPlannedAt}
               defaultValue={defaultPlannedAt}
               onfocus={(event) => event.target.showPicker()}>
        </Input>
    {/if}

    {#if planDateType === 'completed'}
        <Input type="date"
               class="mx-auto my-2 w-full"
               inputClass="py-2"
               name={namePerformedAt}
               label={labelPerformedAt}
               defaultValue={defaultPerformedAt}
               onfocus={(event) => event.target.showPicker()}>
        </Input>
    {/if}
</div>
