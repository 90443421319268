<script>
    import {onMount} from 'svelte';

    import {userStore} from '@js/stores/userStore';

    import {fetchRideCharts} from '@js/actions/rideActions';
    import {computeRideElevation} from '@js/actions/mapActions';

    import * as Utils from '@js/modules/utils';
    import I18n from '@js/modules/translations';
    import Computation from '@js/modules/computation';

    import Notification from '@js/components/layouts/notification';

    import Spinner from '@js/components/themes/Spinner.svelte';

    import AnalyticsService from '@js/modules/analyticsService';
    import {mapStore} from '@js/stores/mapStore';

    const {
        map,
        currentRouteType,
        rideId,
        serializedMapData,
        warningHelper = false,
        onElevationComputed
    } = $props();

    let _charts = null;
    let _previousSerializedDataSize = serializedMapData?.length;
    let isElevationDataLoaded = false;
    const _elevationRequestAbort = {};

    let isShowLoader = $state(false);

    let maximumAltitude = $state(null);
    let minimumAltitude = $state(null);
    let negativeElevation = $state(null);
    let positiveElevation = $state(null);

    const _showElevationChart = (chartData) => {
        import('@js/modules/maps/mapCharts')
            .then((chartModule) => {
                const Charts = chartModule.default;

                if (rideId) {
                    _charts = new Charts({
                        mode: Charts.mode.RIDE,
                        map,
                        rideId
                    });
                } else {
                    _charts = new Charts({
                        map,
                        rideId: 'new'
                    });
                }

                _charts.initializeCharts(chartData);
            });
    };

    const _computeMapElevation = () => {
        return computeRideElevation({
            mapPoints: serializedMapData,
            rideType: currentRouteType
            // distance: mapStore.currentDistance
        }, {
            abort: _elevationRequestAbort
        })
            .then((chartData) => {
                if (!chartData) {
                    return;
                }

                if (chartData.rideElevationError) {
                    const errorMessage = [chartData.rideElevationError, chartData.rideElevationErrorReason].filter(Boolean)
                        .join(' ');

                    AnalyticsService.trackElevationError(errorMessage);

                    Notification.error(errorMessage);
                } else if (chartData.chart) {
                    isElevationDataLoaded = true;

                    _showElevationChart(chartData.chart);

                    minimumAltitude = chartData.chart.minimumAltitude;
                    maximumAltitude = chartData.chart.maximumAltitude;
                    negativeElevation = chartData.chart.negativeElevation;
                    positiveElevation = chartData.chart.positiveElevation;

                    if (onElevationComputed) {
                        onElevationComputed(chartData.chart.points);
                    }
                }
            });
    };

    const _loadElevationData = () => {
        if (rideId) {
            fetchRideCharts(rideId)
                .then((chartData) => {
                    if (!chartData) {
                        return;
                    }

                    isElevationDataLoaded = true;

                    _showElevationChart(chartData.chart);
                });
        } else if (serializedMapData) {
            _computeMapElevation()
                .then(() => {
                    setTimeout(() => Utils.scrollTo('elevation-chart-new', 300), 50);
                });
        }
    };

    onMount(() => {
        if (!isElevationDataLoaded) {
            _loadElevationData();
        }

        return () => {
            if (_charts) {
                _charts.removeCharts();
                _charts = null;
            }
        };
    });

    $effect(() => {
        if (serializedMapData && _previousSerializedDataSize !== serializedMapData?.length) {
            _previousSerializedDataSize = serializedMapData.length;

            isShowLoader = true;
            maximumAltitude = null;
            minimumAltitude = null;
            negativeElevation = null;
            positiveElevation = null;

            if (_elevationRequestAbort?.signal) {
                _elevationRequestAbort.signal.abort();
            }

            if (_charts) {
                _charts.removeCharts();
            }

            _computeMapElevation()
                .then(() => {
                    isShowLoader = false;
                });
        }
    });
</script>

{#snippet elevationFigure(figure, name)}
    <div class="flex items-center justify-center px-2 sm:px-4">
        <div class="flex flex-col items-center justify-between">
            <div class="text-xl font-bold text-gray-900 whitespace-nowrap dark:text-white">
                {Computation.formatDistance(figure, false, false, 3, $userStore.unit)}
            </div>

            <div class="text-xs text-gray-500 whitespace-nowrap dark:text-gray-400">
                {name}
            </div>
        </div>
    </div>
{/snippet}

<div class="map-elevation-graph mt-1">
    <div id={`elevation-chart-${rideId || 'new'}`}
         class="text-center">
        <div class="mt-2">
            {I18n.t('js.ride.elevation.computation')}
        </div>

        <div class="mt-4">
            <Spinner class="me-3"
                     size="8"/>
        </div>
    </div>

    {#if isShowLoader}
        <div class="mt-4 text-center">
            <Spinner class="me-3"
                     size="8"/>
        </div>
    {/if}

    {#if warningHelper}
        <div class="p-2 text-2xs leading-3 text-center text-gray-400 italic">
            {I18n.t('js.ride.show.elevation_info')}
        </div>
    {/if}

    <div class="flex items-center justify-center flex-row p-4">
        {#if positiveElevation}
            {@render elevationFigure(positiveElevation, I18n.t('js.ride.model.elevation_positive'))}
        {/if}
        {#if negativeElevation}
            {@render elevationFigure(negativeElevation, I18n.t('js.ride.model.elevation_negative'))}
        {/if}
        {#if minimumAltitude}
            {@render elevationFigure(minimumAltitude, I18n.t('js.ride.model.minimum_altitude'))}
        {/if}
        {#if maximumAltitude}
            {@render elevationFigure(maximumAltitude, I18n.t('js.ride.model.maximum_altitude'))}
        {/if}
    </div>
</div>

<style>
    .map-elevation-graph {
        padding: .8rem .8rem 0;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
    }
</style>
