import {
    MapLayerNames,
    MapLayers
} from '@js/components/modules/constants';

import {
    updateFetch
} from '@js/actions/fetch';

import * as Utils from '@js/modules/utils';
import I18n from '@js/modules/translations';

import AnalyticsService from '@js/modules/analyticsService';

import StorageService from '@js/modules/storage';


// Monitor map usage
let rasterTilesLoadedCount = 0;
let currentRasterTileIdsSize = 0;
const rasterTileIds = new Set(StorageService.getItem('map_raster_tile_ids') || []);
let rasterMapMaxTilesCount = rasterTileIds.size || parseInt(StorageService.getItem('map_max_raster_tiles_count') || 0);

let vectorTilesLoadedCount = 0;
let currentVectorTileIdsSize = 0;
const vectorTileIds = new Set(StorageService.getItem('map_vector_tile_ids') || []);
let vectorMapMaxTilesCount = vectorTileIds.size || parseInt((StorageService.getItem('map_max_vector_tiles_count')) || 0);


const MapTileService = (function () {
    var mapTileService = {
        layers: MapLayers,

        // Mapbox key
        mapboxAccessToken: window.settings?.mapbox_key,

        // Mapbox styles
        mapPlannerStyle: window.settings?.map_planner_style,

        // Mapbox styles
        mapboxOutdoorStyleEN: window.settings?.mapbox_outdoor_style_en,
        mapboxOutdoorStyleFR: window.settings?.mapbox_outdoor_style_fr,
        mapboxOutdoorStyleDE: window.settings?.mapbox_outdoor_style_de,
        mapboxStreetStyleEN: window.settings?.mapbox_street_style_en,
        mapboxStreetStyleFR: window.settings?.mapbox_street_style_fr,
        mapboxSatelliteStyle: window.settings?.mapbox_satellite_style,

        // // Maptiler
        // maptilerAccessToken: window.settings?.maptiler_key,
        // maptilerOutdoorStyleEN: window.settings?.maptiler_outdoor_style_en,
        // maptilerOutdoorStyleFR: window.settings?.maptiler_outdoor_style_fr,
        // maptilerOutdoorStyleDE: window.settings?.maptiler_outdoor_style_de,

        // // Stadia maps
        // stadiaMapsAccessToken: window.settings?.stadia_maps_key,

        // // Thunder forest
        // thunderForestAccessToken: window.settings?.thunder_forest_key,

        // IGN
        ignAccessToken: window.settings?.ign_key,

        // Product
        showProductPopup: undefined
    };

    var _updateTileService = function (tileService) {
        AnalyticsService.trackMapStyleChanged(MapLayerNames[tileService]);

        window.mapTileService = tileService;

        if (window.currentUserId) {
            StorageService.removeItem('map_tile_service');

            return updateFetch('/api/v1/users/' + window.currentUserId + '/settings', {settings: {mapTileService: tileService}});
        } else {
            StorageService.setItem('map_tile_service', tileService);
        }
    };

    var _tilesLoadedDebounce = Utils.debounce(function (tileType, tilesCount, newTileIds) {
        const newTileIdsSize = newTileIds.size;
        if (tileType === 'raster') {
            if (currentRasterTileIdsSize !== newTileIdsSize) {
                AnalyticsService.trackMapTilesCount(mapTileService.getTileService(), newTileIds.size - currentRasterTileIdsSize);

                currentRasterTileIdsSize = newTileIdsSize;
                StorageService.setItem('map_raster_tile_ids', Array.from(newTileIds));
            }
        } else if (tileType === 'vector') {
            if (currentVectorTileIdsSize !== newTileIdsSize) {
                AnalyticsService.trackMapTilesCount(mapTileService.getTileService(), newTileIds.size - currentRasterTileIdsSize);

                currentVectorTileIdsSize = newTileIdsSize;
                StorageService.setItem('map_vector_tile_ids', Array.from(newTileIds));
            }
        }
    }, 600, false);

    var _maxTilesDebounce = Utils.debounce(function () {
        AnalyticsService.trackMapTilesMaxReached(mapTileService.getTileService());
    }, 600, false);

    mapTileService.getTileService = function () {
        return StorageService.getItem('map_tile_service') || window.mapTileService;
    };

    mapTileService.selectTileStyle = function (tileService, tileOptions) {
        var style;

        if (tileService === MapTileService.layers.MAP_PLANNER || tileService === MapTileService.layers.MAP_PLANNER_OUTDOOR) {
            style = MapTileService.mapPlannerStyle;
        } else if (tileService === MapTileService.layers.MAPBOX_OUTDOOR) {
            var mapboxOutdoorStyle = MapTileService.mapboxOutdoorStyleEN;
            if (window.locale === 'fr') {
                mapboxOutdoorStyle = MapTileService.mapboxOutdoorStyleFR;
            } else if (window.locale === 'de') {
                mapboxOutdoorStyle = MapTileService.mapboxOutdoorStyleDE;
            }

            // // Alternate between vector and raster tiles
            // if (Math.random() < 0.5) {
            style = 'mapbox://styles/' + mapboxOutdoorStyle;
            // } else {
            // style = {
            //     version: 8,
            //     sources: {
            //         'mapbox-tiles': {
            //             type: 'raster',
            //             tiles: ['https://api.mapbox.com/styles/v1/' + mapboxOutdoorStyle + '/tiles/{z}/{x}/{y}@2x?access_token=' + MapTileService.mapboxAccessToken],
            //             tileSize: 512,
            //             attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            //         }
            //     },
            //     layers: [
            //         {
            //             id: 'mapbox-layer',
            //             type: 'raster',
            //             source: 'mapbox-tiles',
            //             minzoom: 0,
            //             maxzoom: 18
            //         }
            //     ]
            // };
            // }
        } else if (tileService === MapTileService.layers.MAPBOX_STREET) {
            var mapboxStreetStyle = MapTileService.mapboxStreetStyleEN;
            if (window.locale === 'fr') {
                mapboxStreetStyle = MapTileService.mapboxStreetStyleFR;
            }

            style = 'mapbox://styles/' + mapboxStreetStyle;

            // Raster mode
            // style = {
            //     version: 8,
            //     sources: {
            //         'mapbox-tiles': {
            //             type: 'raster',
            //             tiles: ['https://api.mapbox.com/styles/v1/' + mapboxStreetStyle + '/tiles/{z}/{x}/{y}@2x?access_token=' + MapTileService.mapboxAccessToken],
            //             tileSize: 512,
            //             attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank">&copy; Mapbox</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            //         }
            //     },
            //     layers: [
            //         {
            //             id: 'mapbox-layer',
            //             type: 'raster',
            //             source: 'mapbox-tiles',
            //             minzoom: 0,
            //             maxzoom: 18
            //         }
            //     ]
            // };
        } else if (tileService === MapTileService.layers.MAPBOX_SATELLITE) {
            var mapboxSatelliteStyle = MapTileService.mapboxSatelliteStyle;

            style = {
                version: 8,
                sources: {
                    'mapbox-tiles': {
                        type: 'raster',
                        tiles: ['https://api.mapbox.com/styles/v1/' + mapboxSatelliteStyle + '/tiles/256/{z}/{x}/{y}@2x?access_token=' + MapTileService.mapboxAccessToken],
                        tileSize: 512,
                        attribution: '<a href="https://www.mapbox.com/about/maps/" target="_blank" title="Mapbox">© Mapbox</a> <a href="https://www.openstreetmap.org/about/" target="_blank" title="OpenStreetMap">© OpenStreetMap</a> <a class="mapbox-improve-map" href="https://www.mapbox.com/contribute/#/5.912/45.693/12" target="_blank" title="Improve this map">Improve this map</a>'
                    }
                },
                layers: [
                    {
                        id: 'mapbox-layer',
                        type: 'raster',
                        source: 'mapbox-tiles',
                        minzoom: 0,
                        maxzoom: 18
                    }
                ]
            };
            // } else if (tileService === MapTileService.layers.MAPTILER) {
            //     var maptilerOutdoorStyle = MapTileService.maptilerOutdoorStyleEN;
            //     if (window.locale === 'fr') {
            //         maptilerOutdoorStyle = MapTileService.maptilerOutdoorStyleFR;
            //     } else if (window.locale === 'de') {
            //         maptilerOutdoorStyle = MapTileService.maptilerOutdoorStyleDE;
            //     }
            //
            //     style = {
            //         version: 8,
            //         sources: {
            //             'maptiler-tiles': {
            //                 type: 'raster',
            //                 tiles: ['https://api.maptiler.com/maps/' + maptilerOutdoorStyle + '/256/{z}/{x}/{y}.png?key=' + MapTileService.maptilerAccessToken],
            //                 tileSize: 256,
            //                 attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
            //             }
            //         },
            //         layers: [
            //             {
            //                 id: 'maptiler-layer',
            //                 type: 'raster',
            //                 source: 'maptiler-tiles',
            //                 minzoom: 0,
            //                 maxzoom: 18
            //             }
            //         ]
            //     };
            // } else if (tileService === MapTileService.layers.OPEN_STREET_MAP_DE) {
            //     style = {
            //         version: 8,
            //         sources: {
            //             'osm-de-tiles': {
            //                 type: 'raster',
            //                 tiles: ['https://tile.openstreetmap.de/{z}/{x}/{y}.png'],
            //                 tileSize: 256,
            //                 attribution: '&copy; <a href="https://openstreetmap.org/copyright" target="_blank">OpenStreetMap contributors</a>'
            //             }
            //         },
            //         layers: [
            //             {
            //                 id: 'osm-de-layer',
            //                 type: 'raster',
            //                 source: 'osm-de-tiles',
            //                 minzoom: 0,
            //                 maxzoom: 18
            //             }
            //         ]
            //     };
            // } else if (tileService === MapTileService.layers.OPEN_STREET_MAP_FR) {
            //     style = {
            //         version: 8,
            //         sources: {
            //             'osm-fr-tiles': {
            //                 type: 'raster',
            //                 tiles: ['https://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png'],
            //                 tileSize: 256,
            //                 attribution: '&copy; <a href="https://openstreetmap.org/copyright" target="_blank">OpenStreetMap contributors</a>'
            //             }
            //         },
            //         layers: [
            //             {
            //                 id: 'osm-fr-layer',
            //                 type: 'raster',
            //                 source: 'osm-fr-tiles',
            //                 minzoom: 0,
            //                 maxzoom: 18
            //             }
            //         ]
            //     };
            // } else if (tileService === MapTileService.layers.STADIA_MAPS) {
            //     style = {
            //         version: 8,
            //         sources: {
            //             'stadia-tiles': {
            //                 type: 'raster',
            //                 tiles: ['https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}@2x.png?api_key=' + MapTileService.stadiaMapsAccessToken],
            //                 tileSize: 256,
            //                 attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a>, &copy; <a href="http://openstreetmap.org">OpenStreetMap contributors</a>'
            //             }
            //         },
            //         layers: [
            //             {
            //                 id: 'stadia-layer',
            //                 type: 'raster',
            //                 source: 'stadia-tiles',
            //                 minzoom: 0,
            //                 maxzoom: 18
            //             }
            //         ]
            //     };
            // } else if (tileService === MapTileService.layers.THUNDER_FOREST) {
            //     style = {
            //         version: 8,
            //         sources: {
            //             'thunderforest-tiles': {
            //                 type: 'raster',
            //                 tiles: ['https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=' + MapTileService.thunderForestAccessToken],
            //                 tileSize: 256,
            //                 attribution: '&copy; <a href="https://www.thunderforest.com">Thunderforest</a>, &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap contributors</a>'
            //             }
            //         },
            //         layers: [
            //             {
            //                 id: 'thunderforest-layer',
            //                 type: 'raster',
            //                 source: 'thunderforest-tiles',
            //                 minzoom: 0,
            //                 maxzoom: 18
            //             }
            //         ]
            //     };
        } else if (tileService === MapTileService.layers.IGN) {
            // IGN layers:
            // Cartes : GEOGRAPHICALGRIDSYSTEMS.MAPS
            // SCAN 100® : SCAN100_PYR-JPEG_WLD_WM
            // SCAN 100® Lambert-93 : SCAN100_PYR-PNG_FXX_LAMB93
            // SCAN 25® Touristique : SCAN25TOUR_PYR-JPEG_WLD_WM
            // SCAN OACI : SCANOACI_PYR-JPEG_WLD_WM

            style = {
                version: 8,
                sources: {
                    'ign-tiles': {
                        type: 'raster',
                        tiles: ['https://wxs.ign.fr/' + MapTileService.ignAccessToken + '/geoportail/wmts?&REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&TILEMATRIXSET=PM&LAYER=GEOGRAPHICALGRIDSYSTEMS.MAPS&STYLE=normal&FORMAT=image/jpeg&TILECOL={x}&TILEROW={y}&TILEMATRIX={z}'],
                        tileSize: 256,
                        attribution: '&copy; <a href="http://www.ign.fr/">IGN</a>'
                    }
                },
                layers: [
                    {
                        id: 'ign-layer',
                        type: 'raster',
                        source: 'ign-tiles',
                        minzoom: 0,
                        maxzoom: 18
                    }
                ]
            };
        } else {
            // Use OSM by default
            // mapTileService === MapTileService.layers.OPEN_STREET_MAP
            style = {
                version: 8,
                sources: {
                    'osm-tiles': {
                        type: 'raster',
                        tiles: ['https://tile.openstreetmap.org/{z}/{x}/{y}.png'],
                        tileSize: 256,
                        attribution: '&copy; <a href="https://openstreetmap.org/copyright" target="_blank">OpenStreetMap contributors</a>'
                    }
                },
                layers: [
                    {
                        id: 'osm-layer',
                        type: 'raster',
                        source: 'osm-tiles',
                        minzoom: 0,
                        maxzoom: 18
                    }
                ]
            };
        }

        AnalyticsService.trackMapStyleUsed(tileService);

        if (tileOptions && tileOptions.isNewStyle && mapTileService.getTileService() !== tileService) {
            _updateTileService(tileService);
        }

        return style;
    };

    mapTileService.monitorMapUsage = function (map, mapLimitations, toggleLoaderFunction) {
        // Check and limit map usage
        if (!mapLimitations || (!mapLimitations.mapMaxRasterTilesCount && !mapLimitations.mapMaxVectorTilesCount)) {
            return;
        }

        map.on('sourcedata', function (event) {
            if (!event.tile) {
                return;
            }

            if (rasterMapMaxTilesCount > mapLimitations.mapMaxRasterTilesCount || vectorMapMaxTilesCount > mapLimitations.mapMaxVectorTilesCount) {
                toggleLoaderFunction(I18n.t('js.maps.map_limitations.tiles'));

                if (MapTileService.showProductPopup) {
                    MapTileService.showProductPopup('map_max_tile_usage');
                }
            }

            var tileId = event.tile.tileID.key;
            // var sourceName = event.sourceId

            if (event.source.type === 'raster') {
                rasterTileIds.add(tileId);

                rasterTilesLoadedCount++;
                rasterMapMaxTilesCount++;

                if (rasterMapMaxTilesCount > mapLimitations.mapMaxRasterTilesCount) {
                    toggleLoaderFunction(I18n.t('js.maps.map_limitations.tiles'));

                    var maxRasterTilesAlreadyReached = StorageService.getItem('map_max_raster_tiles_reached');
                    if (!maxRasterTilesAlreadyReached) {
                        _maxTilesDebounce();
                    }

                    StorageService.setItem('map_max_raster_tiles_count', rasterMapMaxTilesCount);
                    StorageService.setItem('map_max_raster_tiles_reached', true);
                } else {
                    _tilesLoadedDebounce('raster', rasterTilesLoadedCount, rasterTileIds);
                }

                // Each zoom load 6 tiles, save only when necessary for performance
                if (rasterMapMaxTilesCount % 6 === 0) {
                    StorageService.setItem('map_max_raster_tiles_count', rasterMapMaxTilesCount);
                }
            } else if (event.source.type === 'vector') {
                vectorTileIds.add(tileId);

                vectorTilesLoadedCount++;
                vectorMapMaxTilesCount++;

                if (vectorMapMaxTilesCount > mapLimitations.mapMaxVectorTilesCount) {
                    toggleLoaderFunction(I18n.t('js.maps.map_limitations.tiles'));

                    var maxVectorTilesAlreadyReached = StorageService.getItem('map_max_vector_tiles_reached');
                    if (!maxVectorTilesAlreadyReached) {
                        _maxTilesDebounce();
                    }

                    StorageService.setItem('map_max_vector_tiles_count', vectorMapMaxTilesCount);
                    StorageService.setItem('map_max_vector_tiles_reached', true);
                } else {
                    _tilesLoadedDebounce('vector', vectorTilesLoadedCount, vectorTileIds);
                }

                // Each zoom load 6 tiles, save only when necessary for performance
                if (vectorMapMaxTilesCount % 6 === 0) {
                    StorageService.setItem('map_max_vector_tiles_count', vectorMapMaxTilesCount);
                }
            }
        });
    };

    return mapTileService;
})();

export default MapTileService;
