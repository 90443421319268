<script>
    import classnames from 'classnames';

    let {
        buttonElement = $bindable(),
        class: className,
        size = 'base', // xs, sm, base, lg, xl
        color = 'primary', // primary, secondary
        onClick,
        children,
        ...otherProps
    } = $props();

    const sizeCalculator = (s) => {
        switch (s) {
            case 'xs':
                return 'px-3 py-2 text-xs';
            case 'sm':
                return 'px-3 py-2 text-sm';
            case 'lg':
                return 'px-5 py-3 text-base';
            case 'xl':
                return 'px-6 py-3.5 text-base';
            default:
                return 'px-5 py-2.5 text-sm';
        }
    };

    const buttonSize = sizeCalculator(size);

    const colorClasses = {
        primary: 'text-black border-primary-700 bg-primary-300 hover:bg-primary-500 focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800',
        secondary: 'text-black border-secondary-700 bg-secondary-200 hover:bg-secondary-500 focus:ring-secondary-300 dark:bg-secondary-600 dark:hover:bg-secondary-700 dark:focus:ring-secondary-800',
        // red: 'bg-red-700 hover:bg-red-800 focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800',
        // green: 'bg-green-700 hover:bg-green-800 focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800',
        // purple: 'bg-purple-700 hover:bg-purple-800 focus:ring-purple-300 dark:bg-purple-600 dark:hover:bg-purple-700 dark:focus:ring-purple-800',
        teal: 'text-black border-teal-700 hover:bg-teal-200 focus:ring-teal-300 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800',
        yellow: 'text-white bg-yellow-400 hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium dark:focus:ring-yellow-900',
        // orange: 'bg-orange-700 hover:bg-orange-800 focus:ring-orange-300 dark:bg-orange-600 dark:hover:bg-orange-700 dark:focus:ring-orange-800',
        // blue: 'bg-blue-700 hover:bg-blue-800 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
        gray: 'text-gray-700 border-gray-500 bg-white hover:text-white hover:bg-gray-500 focus:ring-2 focus:outline-none focus:ring-gray-300'
    };
</script>

<button type="button"
        class={classnames('font-medium text-center border border-1 rounded-lg focus:ring-2 focus:outline-none', colorClasses[color], buttonSize, className)}
        bind:this={buttonElement}
        onclick={onClick}
        {...otherProps}>
    {@render children()}
</button>
